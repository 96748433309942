import { upload as uploadAPI, publicUpload as publicAPI } from 'api/Media'
import { Media } from 'types/Media'
import heic2any from 'heic2any'

const convertHeicToJPG = async (file: File): Promise<File> => {
  const jpgBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })

  const newFile = new File([jpgBlob], `${file.name}`, {
    lastModified: Math.floor(new Date().getTime() / 1000),
    type: 'image/jpeg',
  })
  return newFile
}

interface UploadProgress {
  percentCompleted: number
  loaded: number
  total: number
}

const onUploadProgress = (callback?: (progress: UploadProgress) => void) => (
  progressEvent: ProgressEvent,
) => {
  const percentCompleted = Math.floor(
    (progressEvent.loaded * 100) / progressEvent.total,
  )
  callback?.({
    percentCompleted,
    loaded: progressEvent.loaded,
    total: progressEvent.total,
  })
}

const upload = async (
  file: File,
  onProgress?: (progress: UploadProgress) => void,
): Promise<Media[]> => {
  const formData = new FormData()
  let fileToUpload = file

  if (
    file.type === 'image/heic' ||
    file?.name?.toLowerCase()?.includes('.heic')
  ) {
    fileToUpload = await convertHeicToJPG(file)
  }

  formData.append('media', fileToUpload)

  return await uploadAPI(formData, onUploadProgress(onProgress))
}

const uploadPublic = async (
  file: File,
  eventHash: string,
  onUploadProgress: (event: ProgressEvent) => void,
): Promise<Media> => {
  const formData = new FormData()

  formData.append('media', file)

  return await publicAPI(formData, eventHash, onUploadProgress)
}

export default {
  upload,
  uploadPublic,
}

export type {
  UploadProgress
}