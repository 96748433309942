import { checkboxTypesType, HistoryItemInterface } from './types'
import { ISMSProjectHistory } from './components/HistoryList/types'
import { StyledPanelHeader } from './NotificationModal.styles'
import moment from 'moment-timezone'
import { ITechnician } from 'types/Appointment'

export const defaultProposalSubject =
  '[ProjectHeader (Estimate, Invoice, etc.)]' + ' from ' + '[company_name]'
export const defaultProposalMessage =
  '<p>Dear client.</p>' +
  '<p>This is [tech_name], with [company_name].</p>' +
  '<p>Please follow this Link to see your [ProjectHeader (Estimate, Invoice, etc.)]</p>' +
  '<p>If you have any questions, please contact us at [company_email] or [company_phone_number]</p>' +
  '<p>Thank you!&nbsp;</p>'

export const messageBrand = `<p style="text-align: right; font-size: 11px; margin-bottom: 0; color: #0000EE">
  <a  href="https://orcatec.com/" style="text-align: right; text-decoration: none">Create your Digital Invoice with Orcatec.com</a>
</p>`

export const initEditorSettings: object = {
  height: 300,
  plugins: ['lists link anchor fullscreen'],
  resize: true,
  browser_spellcheck: true,
}

export const entityType = 1

export const parseProposalStatus = (
  proposalStatus: number | undefined,
): string => {
  let result = ''
  switch (proposalStatus) {
    case 1:
      result = 'Estimate'
      break
    case 2:
      result = 'Contract'
      break
    case 3:
      result = 'Completed'
      break
    case 4:
      result = 'Cancelled'
      break
    case 5:
      result = 'Cold'
      break
    default:
  }
  return result
}

export const checkboxTypes: checkboxTypesType = {
  automatic_follow_up: 'Notification',
  email_proposal: 'Proposal',
  in_route: 'Notification',
  request_for_review: 'Review',
  upcoming_appointment: 'Notification',
}

export const defaultSubjects: { [key: string]: string } = {
  automatic_follow_up: 'Follow up from [company_name]',
  email_proposal:
    '[ProjectHeader (Estimate, Invoice, etc.)] from [company_name]',
  in_route: 'Technician from [company_name] is on his way',
  request_for_review: 'Request for review from [company_name]',
  upcoming_appointment: 'Upcoming Appointment with [company_name]',
}

export const parsePhoneNumbersBeforeSend = (
  phonesArray: string[] = [],
): string[] => {
  const parsedPhones = [...phonesArray]

  const parsePhone = (phone: string): string => {
    let str = phone
    str = str
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')

    return `1${str}`
  }
  return parsedPhones.map(phone => parsePhone(phone))
}

export const parseEmailProposalHistory = (
  history: HistoryItemInterface[],
): any[] => {
  return history.map(
    ({
      data,
      attached_files,
      subject,
      has_attachments,
      history: {
        clients,
        other,
        send_time,
        user_info: { username },
      },
    }) => ({
      body: data ?? '',
      from_name: username ?? '',
      recipients: {
        clients: (() => {
          const arr: { email: any }[] = []
          clients?.forEach?.((c: { emails: any[] }) =>
            c.emails?.forEach?.(e => e.checked && arr.push({ email: e.email })),
          )
          return arr
        })(),
        other: other,
      },
      send_at: send_time ?? '',
      subject: subject ?? '',
      attached_files: attached_files ?? [],
      has_attachments,
    }),
  )
}

export const parseProjectSMSHistory = (history: ISMSProjectHistory[]) =>
  history.map(({ body, recipients, send_at, from }) => ({
    body,
    from_name: from,
    send_at,
    recipients,
  }))

export const renderPanelHeader = (title: string) => (
  <StyledPanelHeader>{title}</StyledPanelHeader>
)

export const getWorkerContact = (
  mainTech?: ITechnician,
  projectTech,
  proposalForm,
) => {
  const user = proposalForm?.id ? projectTech : mainTech

  return { phone: user?.phone, email: user?.email, name: user?.name }
}

export const currentTimeIsBetweenTzTime = (
  currentTime = '09:00:00',
  tmz = 'America/New_York',
  startTime = '09:00:00',
  endTime = '18:00:00',
  fmt = 'HH:mm:ss',
) => {
  const time = moment.tz(currentTime, fmt, tmz)
  const start = moment.tz(startTime, fmt, tmz).format()
  const end = moment.tz(endTime, fmt, tmz).format()
  return time.isBetween(start, end)
}
