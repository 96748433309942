import React from 'react'
import { Input, Form, Select, Tabs } from 'antd'
// import { Editor } from '@tinymce/tinymce-react'
import { Editor } from 'components/UI/Editor/Editor'
import MessagesCount from 'features/Messaging/components/MessagesCount/MessagesCount'
import styled from 'styled-components'

import {
  parsePhoneNumber,
  MESSAGING_SERVICE_IS_NOT_SETUP_TEXT,
} from 'features/Messaging/utils'
import { PreviewHOC } from 'features/Notification/hoc/PreviewHOC'

const { Option } = Select

export const initEditorSettings: object = {
  height: 300,
  plugins: ['lists autolink link anchor fullscreen'],
  resize: false,
  browser_spellcheck: true,
}

export const EditorBlock = ({
  template,
  isMessagingUse,
  handleChangePhone,
  phone,
  phonesList,
  handleChangeTemplate,
  emailValidation,
  industryId,
  // smsValidation,
}) => {
  return (
    <Tabs defaultActiveKey='1'>
      <Tabs.TabPane tab='Email' key='1'>
        <PreviewHOC industryId={industryId} email={template?.email}>
          <InputRow>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign='left'
              required
              label='Sender Name'
              style={{ marginBottom: '6px' }}
              validateStatus={emailValidation.from_name ? 'error' : 'success'}
            >
              <Input
                required
                value={template?.email?.from_name}
                name='from_name'
                placeholder='Sender Name'
                onChange={e => handleChangeTemplate(e, 'email')}
              />
            </Form.Item>
          </InputRow>
          <InputRow>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign='left'
              required
              label='Reply To'
              style={{ marginBottom: '6px' }}
              validateStatus={emailValidation.reply_to ? 'error' : 'success'}
            >
              <Input
                name='reply_to'
                required
                value={template?.email?.reply_to}
                placeholder='Reply To'
                onChange={e => handleChangeTemplate(e, 'email')}
              />
            </Form.Item>
          </InputRow>
          <InputRow>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign='left'
              required
              label='Subject'
              style={{ marginBottom: '6px' }}
              validateStatus={emailValidation.subject ? 'error' : 'success'}
            >
              <Input
                required
                name='subject'
                value={template?.email?.subject}
                placeholder='Subject'
                onChange={e => handleChangeTemplate(e, 'email')}
              />
            </Form.Item>
          </InputRow>
          <Editor
            value={template?.email?.body}
            onEditorChange={value =>
              handleChangeTemplate(
                {
                  target: {
                    name: 'body',
                    value,
                  },
                },
                'email',
              )
            }
          />
          {/* <Editor
            apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
            init={initEditorSettings}
            value={template?.email?.body}
            onEditorChange={value =>
              handleChangeTemplate(
                {
                  target: {
                    name: 'body',
                    value,
                  },
                },
                'email',
              )
            }
          /> */}
        </PreviewHOC>
      </Tabs.TabPane>
      <Tabs.TabPane tab='SMS' key='2'>
        {isMessagingUse ? (
          <PreviewHOC
            sms={{
              ...template?.sms,
              phone: phonesList?.find(item => item.id === phone),
            }}
          >
            {' '}
            <InputRow>
              <Form.Item
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign='left'
                required
                label='Phone Number'
                style={{ marginBottom: '6px' }}
              >
                <Select
                  onChange={handleChangePhone}
                  placeholder='Select phone'
                  style={{ width: '100%' }}
                  value={phone}
                >
                  {phonesList.map(phone => (
                    <Option
                      key={phone.id}
                      value={phone.id}
                      label={phone.alias || phone.number}
                    >
                      <OptionItem>
                        {phone?.alias ? (
                          <>
                            <AliasRow>{phone.alias}</AliasRow>
                            <OptionSubTitle>
                              {parsePhoneNumber(phone.number)}
                            </OptionSubTitle>
                          </>
                        ) : (
                          <>
                            <AliasRow style={{ minHeight: '40px' }}>
                              {parsePhoneNumber(phone.number)}{' '}
                            </AliasRow>
                          </>
                        )}
                      </OptionItem>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </InputRow>
            <Editor
              toolbarHidden
              value={template?.sms?.body}
              onEditorChange={value =>
                handleChangeTemplate(
                  {
                    target: {
                      name: 'body',
                      value,
                    },
                  },
                  'sms',
                )
              }
            />
            {/* <Editor
              apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                statusbar: false,
                toolbar: false,
                browser_spellcheck: true,
              }}
              value={template?.sms?.body}
              onEditorChange={value =>
                handleChangeTemplate(
                  {
                    target: {
                      name: 'body',
                      value,
                    },
                  },
                  'sms',
                )
              }
            /> */}
            <MessagesCount
              styles={{ marginRight: '0px' }}
              value={template?.sms?.body}
            />
          </PreviewHOC>
        ) : (
          <MESSAGING_SERVICE_IS_NOT_SETUP_TEXT />
        )}
      </Tabs.TabPane>
    </Tabs>
  )
}
export const StyledPanelHeader = styled.span`
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
  line-height: 14px;
  margin-top: 7px;
`
const InputRow = styled.div`
  margin-bottom: 10px;
  & label {
    font-weight: 400;
  }
`

const OptionItem = styled.div`
  display: flex;
  flex-direction: column;
`

const AliasRow = styled.div`
  display: flex;
  gap: 10px;
`
const OptionSubTitle = styled.div`
  font-style: italic;
  color: #707683;
  font-size: 0.8rem;
  white-space: break-spaces;
`
