enum NotificationNumType {
  UPCOMING_APPOINTMENT = 1,
  REQUEST_FOR_REVIEW = 2,
  IN_ROUTE = 3,
  PROPOSAL_AUTOMATIC_FOLLOW_UP = 4,
  EMAIL_PROPOSAL = 5,
  APPOINTMENT_REMINDER = 6,
  FOREMAN_LOG = 7,
  UPCOMING_VISIT = 11,
}

enum NotificationPathType {
  UPCOMING_APPOINTMENT = 'upcoming_appointment',
  REQUEST_FOR_REVIEW = 'request_for_review',
  IN_ROUTE = 'in_route',
  PROPOSAL_AUTOMATIC_FOLLOW_UP = 'automatic_follow_up',
  EMAIL_PROPOSAL = 'email_proposal',
  APPOINTMENT_REMINDER = 'appointment_reminder',
  FOREMAN_LOG = 'foreman_log',
}

enum NotificationTitleType {
  UPCOMING_APPOINTMENT = 'Upcoming Event',
  WORKORDER_EVENT = 'Work Order Event',
  REQUEST_FOR_REVIEW = 'Request For Review',
  IN_ROUTE = 'En Route Event',
  PROPOSAL_AUTOMATIC_FOLLOW_UP = 'Proposal Automatic Follow Up',
  EMAIL_PROPOSAL = 'Email Proposal',
  APPOINTMENT_REMINDER = 'Reminder Event',
  FOREMAN_LOG = 'Foreman log sheet',
  CANCELED_APPOINTMENT = 'Canceled Event',
}

enum NotificationTemplateType {
  APPOINTMENT = 'appointment',
  PROJECT = 'proposal',
  GENERAL = 'general',
  WORK_ORDER = 'work_order',
}

enum NotificationModalType {
  UPCOMING = 'upcoming',
  REMINDER = 'reminder',
  PROJECT = 'project',
  EN_ROUTE = 'in_route',
  CANCELED = 'canceled',
  REQUEST_FOR_REVIEW = 'request_for_review',
  WORK_ORDER_EVENT = 'wo_event',
}

enum ProposalActionLog {
  CREATE = 1,
  CHANGE_STATUS = 2,
  SIGN = 3,
  MARK_AS_NOT_SENT = 4,
  MARK_AS_SENT = 5,
  SENT_NOTIFICATION = 6,
}

interface ISettings {
  time_from: string
  time_to: string
  timezone: string
}

interface ITemplates {
  upcoming_appointment: ITemplate
  request_for_review: ITemplate
  email_proposal: ITemplate
  in_route: ITemplate
  automatic_follow_up: ITemplate
  appointment_reminder: ITemplate
}

interface ITemplate {
  email: IEmail
  id: number
  name: string
  sms: ISMS
  type: TypeNotificationNum
}

interface IEmail {
  from_name: string
  reply_to: string
  subject: string
  body: string
  reminder_to_send: boolean
}

interface ISMS {
  body: string
  media: string[]
}

enum TypeNotificationNum {
  TYPE_APPOINTMENT = 1,
  TYPE_PROPOSAL = 2,
  TYPE_GENERAL = 3,
  TYPE_WORK_ORDER = 4,
}
enum TypeNotificationTitle {
  TYPE_APPOINTMENT = 'Appointment',
  TYPE_PROPOSAL = 'Project',
  TYPE_GENERAL = 'General',
  TYPE_WORK_ORDER = 'Work Order',
}

interface INotificationSettings {
  canceled_template_id: null | number
  id: null | number
  in_route_template_id: null | number
  industry_id: number
  project_template_id: null | number
  reminder_template_id: null | number
  request_for_review_template_id: null | number
  should_display_canceled: boolean
  should_display_in_route: boolean
  should_display_request_for_review_event_completed: boolean
  should_display_upcoming: boolean
  should_display_wo_event: boolean
  should_reminder: boolean
  upcoming_template_id: null | number
  wo_event_template_id: null | number

  remind_before:
    | {
        float: string
        natural_time: {
          hours: string | number
          minutes: string | number
        }
      }
    | number
}
interface INotificationColumn {
  name: string
  created_at: string
  type: number
}
export {
  NotificationNumType,
  NotificationPathType,
  NotificationTitleType,
  ITemplates,
  ITemplate,
  ISettings,
  ISMS,
  IEmail,
  TypeNotificationNum,
  TypeNotificationTitle,
  INotificationSettings,
  NotificationTemplateType,
  NotificationModalType,
  INotificationColumn,
  ProposalActionLog,
}
