import { DispatchPermission as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { TypeSourcePermissions } from '../type_source/types'
import { ModuleName } from '../../module_permissions/types'
import { NotesPermissions } from '../notes/types'

export const dispatch = {
  dispatch_can_read: {
    name: 'View Dispatch',
    control_type: UniteType.FIRST_VIEW,
    description: 'Allows users to view Dispatch module',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.DISPATCH_CAN_READ,
    related_key: ['all'],
    is_module: true,
  },
  dispatch_can_change_event_frame: {
    name: 'Change Event Time on Dispatch',
    control_type: UniteType.FIRST_VIEW,
    description: 'Allows to modify event time frames on dispatch',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.DISPATCH_CAN_CHANGE_EVENT_FRAME,
    related_key: [],
  },
  type_source_can_create: {
    name: 'Ability to Create and Delete Type & Source ',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    related_key: [],
    related_modules: ModuleName.TYPE_SOURCE,
    is_module: false,
  },
  dispatch_can_read_event_details: {
    name: 'Event ',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
    description: 'Manages events in the dispatch system',
    with_access_scope: false,
    with_access_checkbox: false,
    related_key: [
      IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
      IKey.DISPATCH_CAN_CREATE_EVENT,
      IKey.DISPATCH_CAN_EDIT_EVENT,
      IKey.DISPATCH_CAN_DELETE_EVENT,
      IKey.DISPATCH_CAN_DUPLICATE_EVENT,
      IKey.DISPATCH_CAN_CHANGE_STATUS,
    ],

    children: [
      {
        name: 'Create Event/Time Off-Vacation ',
        permission_key: IKey.DISPATCH_CAN_CREATE_EVENT,
        with_access_scope: false,
        with_access_checkbox: true,
        descriptio: '',
      },
      {
        name: 'View Event Details ',
        permission_key: IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
        with_access_scope: false,
        with_access_checkbox: true,
        description: '',
        related_key: [
          IKey.DISPATCH_CAN_EDIT_EVENT,
          IKey.DISPATCH_CAN_DELETE_EVENT,
          IKey.DISPATCH_CAN_DUPLICATE_EVENT,
          IKey.DISPATCH_CAN_CHANGE_STATUS,
        ],
        children: [
          {
            name: 'Edit Event ',
            permission_key: IKey.DISPATCH_CAN_EDIT_EVENT,
            parent_permission_key: IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
            with_access_scope: false,
            with_access_checkbox: true,
            description: '',
          },
          {
            name: 'Delete Event ',
            permission_key: IKey.DISPATCH_CAN_DELETE_EVENT,
            parent_permission_key: IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
            with_access_scope: false,
            with_access_checkbox: true,
            description: '',
          },
          {
            name: 'Duplicate Event ',
            permission_key: IKey.DISPATCH_CAN_DUPLICATE_EVENT,
            parent_permission_key: IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
            with_access_scope: false,
            with_access_checkbox: true,
            description: '',
          },
          {
            name: 'Change Event Status  ',
            permission_key: IKey.DISPATCH_CAN_CHANGE_STATUS,
            parent_permission_key: IKey.DISPATCH_CAN_READ_EVENT_DETAILS,
            with_access_scope: false,
            with_access_checkbox: true,
            description: '',
          },
        ],
      },
      {
        name: 'View Group of Events ',
        permission_key: IKey.DISPATCH_CAN_VIEW_GROUP_EVENT,
        with_access_scope: false,
        with_access_checkbox: true,
        description: '',
        related_key: [
          IKey.DISPATCH_CAN_EDIT_GROUP_EVENT,
          IKey.DISPATCH_CAN_DELETE_GROUP_EVENT,
        ],
        children: [
          {
            name: 'Edit Group of Events ',
            permission_key: IKey.DISPATCH_CAN_EDIT_GROUP_EVENT,
            parent_permission_key: IKey.DISPATCH_CAN_VIEW_GROUP_EVENT,
            with_access_scope: false,
            with_access_checkbox: true,
            description: '',
          },
          {
            name: 'Delete Group of Events ',
            permission_key: IKey.DISPATCH_CAN_DELETE_GROUP_EVENT,
            parent_permission_key: IKey.DISPATCH_CAN_VIEW_GROUP_EVENT,
            with_access_scope: false,
            with_access_checkbox: true,
            description: '',
          },
        ],
      },
    ],
  },

  dispatch_can_read_info_for_workers: {
    name: 'Show Info for workers',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to see information for workers',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.DISPATCH_CAN_READ_INFO_FOR_WORKERS,
    related_key: [
      IKey.DISPATCH_CAN_EDIT_INFO_FOR_WORKERS,
      IKey.DISPATCH_CAN_DELETE_INFO_FOR_WORKERS,
    ],
    children: [
      {
        name: 'Edit Info for workers',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.DISPATCH_CAN_EDIT_INFO_FOR_WORKERS,
        parent_permission_key: IKey.DISPATCH_CAN_READ_INFO_FOR_WORKERS,
      },
      {
        name: 'Delete Files for workers',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.DISPATCH_CAN_DELETE_INFO_FOR_WORKERS,
        parent_permission_key: IKey.DISPATCH_CAN_READ_INFO_FOR_WORKERS,
      },
    ],
  },

  notes_can_read: {
    name: 'View Notes',
    with_access_scope: false,
    with_access_checkbox: true,
    control_type: UniteType.THIRD_VIEW,
    permission_key: NotesPermissions.NOTES_CAN_READ,
    parent_permission_key: NotesPermissions.NOTES_CAN_READ,
    description: '',
    is_module: false,
    related_key: [
      NotesPermissions.NOTES_CAN_CREATE,
      NotesPermissions.NOTES_CAN_DELETE,
      NotesPermissions.NOTES_CAN_EDIT,
    ],
    related_modules: ModuleName.NOTES,
    children: [
      {
        name: 'Create a New Note',
        with_access_scope: false,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_CREATE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
      },
      {
        name: 'Edit Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_EDIT,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes  ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
      {
        name: 'Delete Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_DELETE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
    ],
  },

  project_can_read_custom_form: {
    name: 'Show Custom Forms',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to use custom form module',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.DISPATCH_CAN_READ_CUSTOM_FORMS,
    related_key: [
      IKey.DISPATCH_CAN_CREATE_CUSTOM_FORMS,
      IKey.DISPATCH_CAN_EDIT_CUSTOM_FORMS,
      IKey.DISPATCH_CAN_DELETE_CUSTOM_FORMS,
    ],
    // main_key: FormsPermissions.FORMS_LIST_CAN_READ,
    children: [
      {
        name: 'Create Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.DISPATCH_CAN_CREATE_CUSTOM_FORMS,
        parent_permission_key: IKey.DISPATCH_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Edit Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.DISPATCH_CAN_EDIT_CUSTOM_FORMS,
        parent_permission_key: IKey.DISPATCH_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Delete Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.DISPATCH_CAN_DELETE_CUSTOM_FORMS,
        parent_permission_key: IKey.DISPATCH_CAN_READ_CUSTOM_FORMS,
      },
    ],
  },
}
