import React, { FC } from 'react'
import { Spin } from 'antd'

import styled from 'styled-components'
import classNames from 'classnames'

import { Editor as Tiny, IAllProps } from '@tinymce/tinymce-react'

interface IProps extends IAllProps {
  loading: boolean
}

export const Editor: FC<IProps> = ({ loading = false, init = {}, ...rest }) => {
  return (
    <Wrapper className={classNames('editor-cantainer')}>
      <Spin spinning={loading}>
        <Tiny
          tinymceScriptSrc={
            'https://cdn.jsdelivr.net/npm/tinymce@7.1.2/tinymce.min.js'
          }
          init={{
            license_key: 'gpl',
            plugins:
              'lists table autolink link image anchor accordion fullscreen preview save directionality  image link media  anchor  help emoticons',
            ...init,
          }}
          {...rest}
        />
      </Spin>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .editor-wrapper {
    border: 1px solid #d9d9d9 !important;
    min-height: 300px;
    border-radius: 4px;
  }

  .custom-editor {
    height: 210px;
    overflow: scroll !important;
    padding: 10px;
  }
`
