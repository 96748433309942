import { Button, Select } from 'components/UIKit'
import TabsLayout from '../TabsLayout'
import { Checkbox, Popconfirm } from 'antd'
import styled from 'styled-components'
import { useActiveUsers } from 'hooks/useActiveUsers'
import RelatedJobs from 'containers/MainContent/Orcatec/Jobs/components/RelatedJobs'
import { useState } from 'react'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { JobForm } from 'containers/MainContent/Orcatec/Jobs/components/JobPage/components/JobForm/JobForm'
import {
  selectWorkOrderSlice,
  updateWorkorderField,
} from 'features/Project/slices/projectWorkOrdersSlice'
import moment from 'moment-timezone'
import { selectProject } from 'features/Project/projectSelectors'
import { selectRelatedJobs } from 'store/Orcatec/reducers/jobs'
import { setAllJobsToComplete } from 'store/Orcatec/actions/jobs'

interface Props {
  workOrderId: number
}

export const Visits = ({ workOrderId }: Props) => {
  const dispatch = useAppDispatch()

  const { current: workOrder, status } = useAppSelector(selectWorkOrderSlice)
  const project = useAppSelector(selectProject)
  const dictionary = useAppSelector(
    state => state.orcatec.company.dictionary.job,
  )
  const { relatedJobs } = useAppSelector(selectRelatedJobs)

  const { users } = useActiveUsers()

  const [jobModal, showJobModal] = useState(false)

  const handleChangeField = e => {
    const { name, value } = e.target

    dispatch(updateWorkorderField({ [name]: value }))
  }

  const onChangeAllWorkComplited = (e, completeRelateJobs?: boolean) => {
    const { checked } = e.target

    const dateStr = moment().format('MM/DD/YYYY')
    // const value = checked ? 1 : 0
    // onChangeField(checked, 'all_work_completed')
    dispatch(
      updateWorkorderField({
        all_work_completed: checked,
        date_of_completion: checked ? dateStr : null,
        complete_related_jobs: !!completeRelateJobs,
        // issue_date:
        //   companyId === Companies.DivineEnergySolutions ? dateStr : issue_date,
      }),
    )

    if (completeRelateJobs) dispatch(setAllJobsToComplete(!!checked))
  }

  const toggleJobForm = () => showJobModal(!jobModal)

  return (
    <TabsLayout
      title='Visits'
      controls={
        <Controls>
          {!relatedJobs.length ? (
            <Checkbox
              checked={workOrder.all_work_completed}
              onChange={e => {
                if (status === 'loading') return
                onChangeAllWorkComplited(e)
              }}
            >
              Work completed
            </Checkbox>
          ) : (
            <Popconfirm
              title={`Do you want to complete all work order visits?`}
              disabled={!!workOrder.all_work_completed || status === 'loading'}
              onConfirm={() =>
                onChangeAllWorkComplited({ target: { checked: true } }, true)
              }
              onCancel={() =>
                onChangeAllWorkComplited({ target: { checked: true } }, false)
              }
              okText='Yes'
              cancelText='No'
            >
              <Checkbox
                checked={workOrder.all_work_completed}
                onChange={e => {
                  if (!!workOrder.all_work_completed) {
                    onChangeAllWorkComplited(e)
                  }
                }}
              >
                Work completed
              </Checkbox>
            </Popconfirm>
          )}

          <Select
            name='lead_installer_id'
            value={workOrder?.lead_installer_id}
            onChange={handleChangeField}
            options={[
              ...users?.map(user => ({
                value: user?.id,
                label: user?.full_name,
              })),
            ]}
            placeholder='Lead Worker'
          />
          <Button type='primary' onClick={toggleJobForm}>
            Add Visit
          </Button>
        </Controls>
      }
    >
      <RelatedJobs proposalId={workOrderId} hideToggle isWorkOrder />

      {jobModal && (
        <Modal
          title={`Create New ${dictionary.singular}`}
          width={750}
          footer={null}
          open
          centered
          destroyOnClose
          onCancel={toggleJobForm}
          maskClosable={false}
        >
          <JobForm
            proposalId={project?.id}
            workOrderId={workOrderId}
            workOrderName={workOrder?.name}
            onCancel={toggleJobForm}
            onJobCreated={toggleJobForm}
          />
        </Modal>
      )}
    </TabsLayout>
  )
}

const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  align-items: center;
  gap: 16px;
`
