import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Checkbox, notification, Tooltip } from 'antd'
import CustomTable from '../../../components/CustomTable'
import { Input } from 'antd'
import { DatePicker } from 'antd'
import moment from 'moment-timezone'
import * as actions from '../../../../../../store/Orcatec/actions'
import './ModalReminderList.scss'
import {
  deleteReminder,
  fetchRemindersList,
  postReminder,
  editReminder,
} from '../../../../../../api/Proposal'
import Preloader from '../../../components/Preloader'
import validator from 'validator'
// import { Editor } from '@tinymce/tinymce-react'
import { Editor } from 'components/UI/Editor/Editor'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { TextField } from '@material-ui/core'
import { HistoryOutlined } from '@ant-design/icons'
import TableActionsNew from 'containers/MainContent/Orcatec/components/TableActionsNew/index.js'

const ModalReminderList = ({
  onCloseModal,
  showFollowUp,
  proposalId,
  getClientsRelatives,
  userId,
  entityType,
  proposalPropertyId,
  serviceContractId,
  servicePropertyId,
  proposalClients,
  addEmailToClient,
}) => {
  const initial_D = {
    user_id: '1',
    entity_id: '1',
    entity_type: '1', ///// 1 === CONST FOR PROPOSAL, 2 FOR SERVICE CONTRACTS
    message: 'Message to client!',
    send_time: '2020-03-06 10:00:00',
    other: ['test@gmail.com'],
    clients: [],
  }

  const checkboxesForNewEmail = {
    preference: false,
    send_promo: false,
    send_reminder: false,
  }

  const initialState = {
    user_id: '',
    entity_id: '',
    entity_type: entityType, ///// 1 === CONST FOR PROPOSAL
    message: '',
    send_time: '',
    other: [''],
    clients: [],
  }

  const initialNewEmailData = {
    client: { id: 0 },
    email: '',
  }

  const initialPagination = {
    total: 0,
    limit: 10,
    page: 1,
  }

  const [showAddModal, setShowAddModal] = useState(false)
  const [customEmailChecked, setCustomEmailChecked] = useState(false)
  const [date, setDate] = useState({})
  const [time, setTime] = useState(moment())
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState(false)
  const [addNewData, setAddNewData] = useState(initialState)
  const [sortName, changeSort] = useState('status')
  const [pagination, setPagination] = useState(initialPagination)
  const [reminders, setReminders] = useState([])
  const [preloaderState, setPreloaderState] = useState('')
  const [disabledAllFields, setDisabledAllFields] = useState(false)
  const [sort_type, changeSortType] = useState('asc')

  const [clientsList, setClientsList] = useState([])
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorEditor, setErrorEditor] = useState(false)
  const [errorDate, setErrorDate] = useState(false)
  const [errorClient, setErrorClient] = useState('')
  const [updateClientList, setUpdateClientList] = useState(true)
  const clientEl = useRef(null)
  const [newClientEmailData, setNewClientEmailData] = useState(
    initialNewEmailData,
  )
  const [newClientEmailDataError, setNewClientEmailDataError] = useState('')
  const [emailAdding, setEmailAdding] = useState(false)

  useEffect(() => {
    if (proposalId || serviceContractId) {
      const data = {
        ...pagination,
        entity_id:
          entityType === '1'
            ? proposalId
            : entityType === '2'
            ? serviceContractId
            : '',
        entity_type: entityType,
        order: sort_type,
        sort: sortName,
      }
      fetchRemindersList(data).then(res => {
        setReminders(res.data)
        setPagination({ ...pagination, total: res.total })
      })
    }
  }, [proposalId || serviceContractId])
  useEffect(() => {
    if (proposalId || serviceContractId) {
      const data = {
        ...pagination,
        entity_id:
          entityType === '1'
            ? proposalId
            : entityType === '2'
            ? serviceContractId
            : '',
        entity_type: entityType,
        order: sort_type,
        sort: sortName,
      }
      fetchRemindersList(data).then(res => {
        setReminders(res.data)
        setPagination({ ...pagination, total: res.total })
      })
    }
  }, [showAddModal])

  useEffect(() => {
    const id =
      entityType === '1'
        ? proposalPropertyId
        : entityType === '2'
        ? servicePropertyId
        : ''
    if (proposalId || serviceContractId) {
      setAddNewData({ ...addNewData, entity_id: id })
      setClientsList(proposalClients)
    }
  }, [proposalId, serviceContractId, updateClientList, proposalClients])

  useEffect(() => {
    if (!time) setTime(moment().hour(9))
  }, [time])

  const head = [
    { label: 'Status', sort: true, sort_name: 'status' },
    { label: 'Date/Time', sort: true, sort_name: 'send_time' },
    { label: 'Client', sort: true, sort_name: 'first_client' },
    { label: 'Message', sort: false },
    { label: 'Actions', sort: false },
  ]

  const rows = reminders.map((item, idx) => {
    let localDate = moment
      .utc(item.send_time, 'YYYY-MM-DD HH:00:00')
      .local()
      .format('MM/DD/YYYY HH:mm:ss a')
    return (
      <tr key={idx}>
        <td>{item.status === 1 ? 'scheduled' : 'sent'}</td>
        <td>
          <Tooltip title={localDate}>{localDate}</Tooltip>
        </td>
        <td>{item.clients.map(item => item.name)}</td>
        <td className='messagePreview'>
          <div dangerouslySetInnerHTML={{ __html: item.message }} />
        </td>
        <td>
          {item.status === 1 ? (
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <i
                style={{ marginRight: '20px' }}
                className='mdi mdi-pencil modal-icon'
                onClick={() => editReminderFunc(item)}
              />
              {preloaderState === `delete-${item.id.toString()}` ? (
                <Preloader />
              ) : (
                <i
                  className='mdi mdi-delete-forever modal-icon'
                  onClick={() => deleteReminderFunc(item.id)}
                />
              )}
            </div>
          ) : (
            <i
              className='mdi mdi-eye modal-icon'
              onClick={() => openReminder(item)}
            />
          )}
        </td>
      </tr>
    )
  })

  const openNotificationWithIcon = (type, data) => {
    notification[type]({
      ...data,
    })
  }

  const openReminder = item => {
    setAddNewData(item)
    setShowAddModal(true)
    setClientsList(item.clients)
    currentDate = moment(moment(item.send_time)).format('MM-DD-YYYY')
    const momentDate = moment(
      new Date(moment(currentDate).format('MM-DD-YYYY')),
    )
    setDate(moment(item.send_time))
    setTime(moment(item.send_time))
    setAddNewData(item)
    setShowAddModal(true)
    setEdit(true)
    setEditId(item.id)
    setDisabledAllFields(true)
    if (item.other[0]) {
      setCustomEmailChecked(true)
    } else {
      setCustomEmailChecked(false)
    }
  }
  const deleteReminderFunc = id => {
    const data = {
      ...pagination,
      entity_id:
        entityType === '1'
          ? proposalId
          : entityType === '2'
          ? serviceContractId
          : '',
      entity_type: entityType,
      order: sort_type,
      sort: sortName,
    }
    setPreloaderState(`delete-${id.toString()}`)
    deleteReminder(id).then(() =>
      fetchRemindersList(data).then(res => {
        setReminders(res.data)
        setPreloaderState('')
        setPagination({ ...pagination, total: res.data?.length })

        openNotificationWithIcon('success', {
          message: 'Reminder was deleted',
          description: '',
          key: 'property-save-in-process',
          duration: 4,
        })
      }),
    )
  }

  let currentDate = ''

  const editReminderFunc = item => {
    setDisabledAllFields(false)
    setAddNewData(item)
    setShowAddModal(true)
    setEdit(true)
    setClientsList(item.clients)
    setEditId(item.id)
    currentDate = moment(moment(item.send_time)).format('MM-DD-YYYY')
    const momentDate = moment(
      new Date(moment(currentDate).format('MM-DD-YYYY')),
    )
    setDate(momentDate)
    setTime(moment(item.send_time))
    if (item.other[0]) {
      setCustomEmailChecked(true)
    } else {
      setCustomEmailChecked(false)
    }
  }

  const onSort = sort_name => {
    changeSort(sort_name)
    sort_type === 'asc' ? changeSortType('desc') : changeSortType('asc')
    fetchRemindersList({
      page: pagination.page,
      limit: pagination.limit,
      sort: sort_name,
      order: sort_type,
      entity_id:
        entityType === '1'
          ? proposalId
          : entityType === '2'
          ? serviceContractId
          : '',
      entity_type: entityType,
    }).then(res => {
      setReminders(res.data)
      setPagination({ ...pagination, total: res.total })
    })
  }

  const changeDate = e => {
    setErrorDate(false)
    setDate(e)
  }

  const onChecked = (e, item) => {
    const newClientList = [...clientsList]
    const client = newClientList.find(client => client.id === item.id)
    const emailData = client.emails.find(item => item.email === e.target.email)

    if (e.target.checked) {
      emailData.checked = true
      clientEl.current.className = 'client_wrapper'
      setErrorClient('')
    } else {
      emailData.checked = false
    }

    setClientsList(newClientList)
  }

  const closeNewEmail = () => {
    setNewClientEmailData(initialNewEmailData)
    setNewClientEmailDataError('')
  }

  const addNewEmail = () => {
    if (
      !newClientEmailDataError ||
      newClientEmailDataError === 'Please try again'
    ) {
      if (newClientEmailData.email) {
        setEmailAdding(true)
        const resultData = {
          id: 0,
          email: newClientEmailData.email,
          client_id: newClientEmailData.client.id,
          entity_id: newClientEmailData.client.id,
          entity_type: newClientEmailData.client.type === 1 ? 1 : 2,
          ...checkboxesForNewEmail,
        }
        addEmailToClient(resultData)
          .then(() => {
            closeNewEmail()
            setEmailAdding(false)
          })
          .catch(err => {
            setNewClientEmailDataError('Please try again')
            setEmailAdding(false)
          })
      } else {
        setNewClientEmailDataError('Input valid email please')
      }
    }
  }

  const initNewEmailData = client => e => {
    if (!!e && !!e.preventDefault) e.preventDefault()
    setNewClientEmailData({ ...initial_D, client })
  }

  const onChangeNewEmail = e => {
    const { name, value } = e.target
    const isEmail = value => validator.isEmail(value)
    const newEmailErrorMessage =
      !!value && isEmail(value)
        ? !!value &&
          (newClientEmailData.client.emails || []).some(
            emailData => emailData.email === value,
          )
          ? 'This email already exists'
          : ''
        : 'Input valid email please'
    setNewClientEmailData(prev => ({ ...prev, email: value }))
    setNewClientEmailDataError(newEmailErrorMessage)
  }

  const tableActionProps = client => ({
    todos: ['confirm', 'close'],
    callbacks: [addNewEmail, closeNewEmail],
    preloaders: [
      emailAdding && client.id === newClientEmailData.client.id,
      false,
    ],
    tooltips: ['Add email', 'Close'],
  })

  const Names =
    clientsList &&
    clientsList.map((item, idx) => {
      return (
        <div key={idx}>
          <div className='client_container'>
            <div className='client_name'>
              <span>{item.name}</span>
              <div>
                {newClientEmailData.client.id !== item.id && (
                  <span
                    className='client_name-add'
                    onClick={
                      newClientEmailData.client.id
                        ? () => {}
                        : initNewEmailData(item)
                    }
                  >
                    Add new email to this client
                  </span>
                )}
              </div>
            </div>
            <div className='client_email'>
              {item.emails.map((emailData, inx) => (
                <div className='email_box' key={inx}>
                  <Checkbox
                    email={emailData.email}
                    disabled={disabledAllFields}
                    onChange={e => onChecked(e, item)}
                    checked={emailData.checked}
                  />
                  <span className='email'>{emailData.email}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='client_container'>
            <div className='client_name'></div>
            <div className='client_email client_email-add'>
              {newClientEmailData.client.id === item.id ? (
                <>
                  <TextField
                    id={`new_email-${item.id}`}
                    type='email'
                    name='new_email'
                    placeholder='Type email'
                    value={newClientEmailData.email}
                    onChange={onChangeNewEmail}
                    variant='outlined'
                    error={!!newClientEmailDataError}
                    helperText={newClientEmailDataError}
                    fullWidth={true}
                    style={{ marginRight: '10px' }}
                    size='small'
                  />
                  <TableActionsNew {...tableActionProps(item)} />
                </>
              ) : null}
            </div>
          </div>
        </div>
      )
    })

  const onChange = e => {
    e.preventDefault()
    const { name, value } = e.currentTarget
    const isEmail = validator.isEmail(value)

    if (isEmail) {
      setErrorEmail(false)
      setCustomEmailChecked(true)
      clientEl.current.className = 'client_wrapper'
      setErrorClient('')
    } else {
      setErrorEmail(true)
      setCustomEmailChecked(false)
    }

    setAddNewData({ ...addNewData, other: [value] })
  }

  const onChangeEditor = value => {
    setAddNewData({ ...addNewData, message: value })
    setErrorEditor(false)
  }

  const onChangeStartTime = time => {
    setTime(time)
  }

  const onSendReminder = () => {
    const dateValue = moment(date).format('YYYY-MM-DD')
    const timeValue = moment(time).format('HH:00:00')
    const convertUtc = moment(Date.parse(dateValue + ' ' + timeValue))
      .utc()
      .format('YYYY-MM-DD HH:00:00')

    if (dateValue === 'Invalid date') {
      setErrorDate(true)
      return
    }

    if (addNewData.message === '') {
      setErrorEditor(true)
      return
    }

    if (customEmailChecked === false) {
      postReminder({
        ...addNewData,
        send_time: convertUtc,
        user_id: userId.toString(),
        clients: clientsList.map(client => ({
          ...client,
          emails: client.emails.map(email => ({
            ...email,
            checked:
              typeof email.checked === 'undefined' ? false : email.checked,
          })),
        })),
        entity_id:
          entityType === '1'
            ? proposalId
            : entityType === '2'
            ? serviceContractId
            : '',
        other: [],
      })
        .then(() => {
          setShowAddModal(false)
          setUpdateClientList(!updateClientList)

          openNotificationWithIcon('success', {
            message: 'Reminder was created',
            description: '',
            key: 'property-save-in-process',
            duration: 4,
          })
        })
        .catch(errorMessage => {
          setErrorClient(errorMessage?.response?.data?.clients?.[0])
          clientEl.current.className += ' error_client'
        })
    } else {
      const customEmail = customEmailChecked ? addNewData.other : ''

      postReminder({
        ...addNewData,
        send_time: convertUtc,
        clients: clientsList,
        user_id: userId.toString(),
        entity_id:
          entityType === '1'
            ? proposalId
            : entityType === '2'
            ? serviceContractId
            : '',
        other: customEmail,
      })
        .then(() => {
          setShowAddModal(false)
          setUpdateClientList(!updateClientList)

          openNotificationWithIcon('success', {
            message: 'Reminder was created',
            description: '',
            key: 'property-save-in-process',
            duration: 4,
          })
        })
        .catch(errorMessage => {
          setErrorClient(errorMessage?.response?.data?.clients?.[0])
          clientEl.current.className += ' error_client'
        })
    }
  }

  const onEditReminder = () => {
    const dateValue = moment(date).format('YYYY-MM-DD')
    const timeValue = moment(time).format('HH:00:00')
    const convertUtc = moment(Date.parse(dateValue + ' ' + timeValue))
      .utc()
      .format('YYYY-MM-DD HH:00:00')
    //const local = moment.utc(convertUtc, 'YYYY-MM-DD HH:00:00').local().format('MM/DD/YYYY HH:mm')

    if (dateValue === 'Invalid date') {
      setErrorDate(true)
      return
    }

    if (addNewData.message === '') {
      setErrorEditor(true)
      return
    }

    if (customEmailChecked === false) {
      editReminder(editId, {
        ...addNewData,
        send_time: convertUtc,
        user_id: userId.toString(),
        entity_id:
          entityType === '1'
            ? proposalId
            : entityType === '2'
            ? serviceContractId
            : '',
        other: [],
      })
        .then(() => {
          setShowAddModal(false)
          setUpdateClientList(!updateClientList)

          openNotificationWithIcon('success', {
            message: 'Reminder was updated',
            description: '',
            key: 'property-save-in-process',
            duration: 4,
          })
        })
        .catch(errorMessage => {
          setErrorClient(errorMessage?.response?.data?.clients?.[0])
          clientEl.current.className += ' error_client'
        })
    } else {
      const customEmail = customEmailChecked ? addNewData.other : ''

      editReminder(editId, {
        ...addNewData,
        send_time: convertUtc,
        user_id: userId.toString(),
        entity_id:
          entityType === '1'
            ? proposalId
            : entityType === '2'
            ? serviceContractId
            : '',
        other: customEmail,
      })
        .then(() => {
          setShowAddModal(false)
          setUpdateClientList(!updateClientList)

          openNotificationWithIcon('success', {
            message: 'Reminder was updated',
            description: '',
            key: 'property-save-in-process',
            duration: 4,
          })
        })
        .catch(errorMessage => {
          setErrorClient(errorMessage?.response?.data?.clients?.[0])
          clientEl.current.className += ' error_client'
        })
    }
  }

  const isCustomEmail = e => {
    if (e.target.checked) {
      setCustomEmailChecked(true)
    } else {
      setCustomEmailChecked(false)
    }
  }

  return (
    <Modal
      show={showFollowUp}
      size='xl'
      className='modal-proposal-reminder'
      onHide={onCloseModal}
    >
      {!showAddModal ? (
        <>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='modal_title'>Automatic Follow-up</div>
            <CustomTable
              head={head}
              rows={rows}
              className='reminder_list_table'
              onSort={onSort}
              total={pagination.total}
              pageSize={pagination.limit}
              page={pagination.page}
              onChangeLimit={limit =>
                fetchRemindersList({
                  page: pagination.page,
                  limit,
                  entity_id:
                    entityType === '1'
                      ? proposalId
                      : entityType === '2'
                      ? serviceContractId
                      : '',
                  entity_type: entityType,
                  order: sort_type,
                  sort: sortName,
                }).then(res => {
                  setReminders(res.data)
                  setPagination({
                    total: res.total,
                    page: res.page,
                    limit: res.limit,
                  })
                })
              }
              onChangePage={page =>
                fetchRemindersList({
                  page,
                  limit: pagination.limit,
                  entity_id:
                    entityType === '1'
                      ? proposalId
                      : entityType === '2'
                      ? serviceContractId
                      : '',
                  entity_type: entityType,
                  order: sort_type,
                  sort: sortName,
                }).then(res => {
                  setReminders(res.data)
                  setPagination({
                    total: res.total,
                    page: res.page,
                    limit: res.limit,
                  })
                })
              }
              // hideSearch={true}
              search={false}
              // onSearch={e => {
              //     changeValue(e.currentTarget.value)
              // }}
            >
              {rows}
            </CustomTable>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <Button
                  onClick={() => {
                    onCloseModal()
                    setUpdateClientList(!updateClientList)
                  }}
                  style={{
                    background: '#fff',
                    color: '#000000',
                    border: 'solid 1px #d6d6d6',
                    padding: '10px 20px',
                    marginRight: '10px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDisabledAllFields(false)
                    setShowAddModal(true)
                    setEdit(false)
                    setAddNewData(initialState)
                    setDate('')
                    setTime('')
                    setCustomEmailChecked(false)
                  }}
                  style={{
                    background: 'rgba(52, 159, 166, 0.75)',
                    border: 0,
                    padding: '10px 20px',
                  }}
                >
                  Add new
                </Button>
              </div>
            </div>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='modal_title'>Automatic Follow-up</div>
            <div className='box_title'>Select Client</div>
            <div className='reminder_parent'>
              <div ref={clientEl} className='client_wrapper'>
                {Names}
                <div className='other_email_container'>
                  <div className='title'>Other</div>
                  <div className='email_item'>
                    <Checkbox
                      onChange={e => isCustomEmail(e)}
                      checked={customEmailChecked}
                      disabled={disabledAllFields}
                    />
                    <TextField
                      disabled={disabledAllFields}
                      className='emailInput other_email'
                      size='large'
                      name='email'
                      type='email'
                      placeholder='other email'
                      value={addNewData.other}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              {errorClient && (
                <span className='error_editor'>{errorClient}</span>
              )}

              <div className='date_time_container'>
                <div className='date_box'>
                  <label className='l_date'>Date</label>
                  <DatePicker
                    disabled={disabledAllFields}
                    size='large'
                    // defaultValue={date}
                    value={date}
                    className='form-control datePickerModal'
                    style={{ border: 0 }}
                    placeholderText='Select day'
                    onChange={changeDate}
                    getCalendarContainer={() =>
                      document.getElementsByClassName(
                        'modal-proposal-reminder',
                      )[0]
                    }
                  />
                </div>

                <div className='time_box'>
                  <label className='l_time'>Time</label>
                  <KeyboardTimePicker
                    disabled={disabledAllFields}
                    keyboardIcon={<HistoryOutlined className='timeIcon' />}
                    // size="large"
                    // showHour={true}
                    // use12Hours={true}
                    showMinute={false}
                    minutesStep={60}
                    views={['hours']}
                    // showSecond={false}
                    format='hh A'
                    className='timePickerModal form-control'
                    value={time}
                    onChange={onChangeStartTime}
                  />
                </div>
              </div>

              <div className='editor_container'>
                <div className='title_editor'>Message to client</div>
                <Editor
                  disabled={disabledAllFields}
                  value={addNewData.message}
                  onEditorChange={onChangeEditor}
                />
                {/* <Editor
                  disabled={disabledAllFields}
                  apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
                  initialValue='<p>This is the initial content of the editor</p>'
                  init={{
                    height: 300,
                    toolbar:
                      'fontselect formatselect | bold italic strikethrough forecolor backcolor formatpainter| alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | code',
                  }}
                  value={addNewData.message}
                  // maxChars={10}
                  // name='message'
                  onEditorChange={onChangeEditor}
                /> */}
                {errorEditor && (
                  <span className='error_editor'>
                    Please input your message to client
                  </span>
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <Button
                  onClick={() => {
                    setUpdateClientList(!updateClientList)
                    if (disabledAllFields) setDisabledAllFields(false)
                    setShowAddModal(false)
                  }}
                  style={{
                    background: '#fff',
                    color: '#000000',
                    border: 'solid 1px #d6d6d6',
                    padding: '10px 20px',
                    marginRight: '10px',
                  }}
                >
                  {disabledAllFields ? 'Cancel view' : 'Cancel'}
                </Button>
                <Button
                  disabled={disabledAllFields}
                  onClick={!edit ? onSendReminder : onEditReminder}
                  style={{
                    background: '#4C53EF',
                    border: 0,
                    padding: '10px 20px',
                  }}
                >
                  Schedule
                </Button>
              </div>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    proposalPropertyId: state.orcatec.proposalForm.property_id
      ? state.orcatec.proposalForm.property_id
      : '',
    servicePropertyId: state.orcatec.serviceContract.property
      ? state.orcatec.serviceContract.property.id
      : '',
    proposalId: state.orcatec.proposalForm.id
      ? state.orcatec.proposalForm.id
      : '',
    proposalClients: state.orcatec.proposalForm.id
      ? state.orcatec.proposalForm.clients
      : '',
    serviceContractId: state.orcatec.serviceContract.currentContract
      ? state.orcatec.serviceContract.currentContract.id
      : '',
    userId: state.orcatec.user.me.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getClientsRelatives: id =>
      dispatch(actions.proposalForm.getClientsRelatives(id)),
    addEmailToClient: email =>
      dispatch(actions.proposalForm.addEmailToClient(email)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalReminderList)
