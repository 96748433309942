import { JobPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const createJobsPermissions = ({ singular, plural }) => ({
  jobs_list_can_read: {
    name: `View ${plural} List`,
    control_type: UniteType.THIRD_VIEW,
    description: '',
    with_access_scope: true,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.JOBS_LIST_CAN_READ,
    related_key: [IKey.JOBS_CAN_DELETE],
    is_module: true,
    children: [
      {
        name: `Delete ${singular}`,
        description: '',
        with_access_scope: false,
        with_access_checkbox: true,
        accordion: false,
        permission_key: IKey.JOBS_CAN_DELETE,
        parent_permission_key: IKey.JOBS_LIST_CAN_READ,
      },
    ],
  },

  jobs_can_view_group_event: {
    name: 'View Group of Visits ',
    permission_key: IKey.JOBS_CAN_VIEW_GROUP_EVENT,
    with_access_scope: false,
    with_access_checkbox: true,
    description: '',
    related_key: [
      IKey.JOBS_CAN_EDIT_GROUP_EVENT,
      IKey.JOBS_CAN_DELETE_GROUP_EVENT,
    ],
    children: [
      {
        name: 'Edit Group of Visits ',
        permission_key: IKey.JOBS_CAN_EDIT_GROUP_EVENT,
        parent_permission_key: IKey.JOBS_CAN_VIEW_GROUP_EVENT,
        with_access_scope: false,
        with_access_checkbox: true,
        description: '',
      },
      {
        name: 'Delete Group of Visits ',
        permission_key: IKey.JOBS_CAN_DELETE_GROUP_EVENT,
        parent_permission_key: IKey.JOBS_CAN_VIEW_GROUP_EVENT,
        with_access_scope: false,
        with_access_checkbox: true,
        description: '',
      },
    ],
  },

  jobs_can_read_info_for_workers: {
    name: 'Show Info for workers',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to see information for workers',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.JOBS_CAN_READ_INFO_FOR_WORKERS,
    related_key: [
      IKey.JOBS_CAN_EDIT_INFO_FOR_WORKERS,
      IKey.JOBS_CAN_DELETE_INFO_FOR_WORKERS,
    ],
    children: [
      {
        name: 'Edit Info for workers',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.JOBS_CAN_EDIT_INFO_FOR_WORKERS,
        parent_permission_key: IKey.JOBS_CAN_READ_INFO_FOR_WORKERS,
      },
      {
        name: 'Delete Files for workers',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.JOBS_CAN_DELETE_INFO_FOR_WORKERS,
        parent_permission_key: IKey.JOBS_CAN_READ_INFO_FOR_WORKERS,
      },
    ],
  },

  project_can_read_custom_form: {
    name: 'Show Custom Forms',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to use custom form module',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.JOBS_CAN_READ_CUSTOM_FORMS,
    related_key: [
      IKey.JOBS_CAN_CREATE_CUSTOM_FORMS,
      IKey.JOBS_CAN_EDIT_CUSTOM_FORMS,
      IKey.JOBS_CAN_DELETE_CUSTOM_FORMS,
    ],
    // main_key: FormsPermissions.FORMS_LIST_CAN_READ,
    children: [
      {
        name: 'Create Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.JOBS_CAN_CREATE_CUSTOM_FORMS,
        parent_permission_key: IKey.JOBS_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Edit Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.JOBS_CAN_EDIT_CUSTOM_FORMS,
        parent_permission_key: IKey.JOBS_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Delete Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.JOBS_CAN_DELETE_CUSTOM_FORMS,
        parent_permission_key: IKey.JOBS_CAN_READ_CUSTOM_FORMS,
      },
    ],
  },
})

export const jobs = createJobsPermissions({ singular: 'Job', plural: 'Jobs' })
