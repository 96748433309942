import { EditOutlined } from '@ant-design/icons'
import { Skeleton, Tooltip } from 'antd'
import { DatePicker, Select, TextField } from 'components/UIKit'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import { selectProject } from 'features/Project/projectSelectors'
import {
  // deleteWorkOrder,
  selectWorkOrderSlice,
  updateWorkorderField,
} from 'features/Project/slices/projectWorkOrdersSlice'
// import { ProjectStatusGroup } from 'features/Project/types'
import {
  ModuleName,
  ProjectPermissions,
  TypeSourcePermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
// import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'

export const MainInfo = () => {
  const dispatch = useAppDispatch()
  // const history = useHistory()

  const me = useAppSelector(selectAuthUser)
  const { current: workOrder, status } = useAppSelector(selectWorkOrderSlice)
  const project = useAppSelector(selectProject)
  const techs = useAppSelector(state => state.orcatec.company.technicians)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const canEditProjectDetails = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_RELATIONS],
    [project.user_id, ...(project?.extraAssignedUsers || [])],
    me?.id,
  )

  const [workOrderName, setWorkOrderName] = useState('')

  useEffect(() => {
    setWorkOrderName(workOrder.name)
  }, [workOrder.name])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setWorkOrderName(e.target.value)

  /*   const canDeleteProject = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_CAN_DELETE],
    workOrder.user_id,
    me.id,
  ) */

  /* const handleProjectDelete = async () => {
    if (!canDeleteProject) {
      return openNotificationWithIcon('warning', {
        message: 'You do not have permissions to perform this action',
      })
    }

    const res = await dispatch(deleteWorkOrder(workOrder.id))

    if (res.meta.requestStatus === 'rejected') {
      return openNotificationWithIcon('error', {
        message: res?.payload?.response?.data?.message || 'Project not found',
      })
    }

    history.replace('/projects')
  } */

  const handleInfoUpdate = e => {
    const { name, value } = e.target

    const field = { [name]: value }
    dispatch(updateWorkorderField(field))
  }

  /* const disabled = ![
    ProjectStatusGroup.Estimate,
    ProjectStatusGroup.Cold,
  ].includes(project.status) */

  const handleTechUpdate = (e: { target: { name: string; value: number } }) => {
    const { value } = e.target

    dispatch(
      updateWorkorderField({
        user_id: value,
        user: techs.find(tech => tech.id === value),
      }),
    )
  }

  if (status === 'loading') return <Skeleton active />

  return (
    <ErrorBoundary>
      <Wrapper>
        <Row>
          <Title>Work order name:</Title>
          <TextField
            name='name'
            value={workOrderName}
            onChange={handleNameChange}
            placeholder='{No Work order name}'
            onBlur={handleInfoUpdate}
            className='input-with-padding'
          />
          <EditIcon />
        </Row>

        <Row>
          <Title>Created from:</Title>
          <p>{project.code}</p>
        </Row>

        <Row>
          <Title>Created by:</Title>
          <p style={{ whiteSpace: 'break-spaces' }}>
            {workOrder.created_by?.full_name}{' '}
            {moment
              .utc(workOrder.created_at)
              .local()
              .format('[on] MM/DD/YYYY [at] hh:mm a')}
          </p>
        </Row>

        <Row>
          <Title>Assigned to:</Title>
          <Select
            value={workOrder.user_id}
            name='user_id'
            options={techs}
            onChange={handleTechUpdate}
            showSearch={false}
            fieldNames={{ label: 'full_name', value: 'id' }}
          />
          <EditIcon />
        </Row>

        {/*  {project?.appointment_id && (
          <Row>
            <Title>From appointment:</Title>
            <StyledLink to={`/appointment/${project?.appointment_id}`}>
              Appointment #{project?.appointment_id}
            </StyledLink>
          </Row>
        )}

        {project.template_name && (
          <Row>
            <Title>From template:</Title>
            <p style={{ alignSelf: 'flex-end' }}>{project.template_name}</p>
          </Row>
        )} */}

        {/*   <Row disabled={!canEditProjectDetails}>
          <Title>Industry:</Title>
          <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : disabled
                ? 'Industry can only be changed when project is in Estimate status'
                : ''
            }
          >
            <Select
              value={project.industry_id}
              name='industry_id'
              options={industries}
              onChange={handleInfoUpdate}
              disabled={disabled || !canEditProjectDetails}
              showSearch={false}
            />
          </Tooltip>
          <EditIcon disabled={disabled || !canEditProjectDetails} />
        </Row> */}

        <Row disabled={!canEditProjectDetails}>
          <Title>Date of completion:</Title>
          <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : ''
            }
          >
            <DatePicker
              value={workOrder?.date_of_completion}
              onChange={(_, value) =>
                handleInfoUpdate({
                  target: {
                    name: 'date_of_completion',
                    value,
                  },
                })
              }
              allowClear={false}
              disabled={!canEditProjectDetails}
            />
          </Tooltip>
        </Row>

        <Row disabled={!canEditProjectDetails}>
          <Title>Type:</Title>
          {/* <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : ''
            }
          > */}
          <AppointmentTypes
            name='type_id'
            value={workOrder.type_id}
            onChange={handleInfoUpdate}
            isLead={false}
            required={false}
            disableCRUD={!canEditTypeSource}
            withoutLabel
            withoutColor
            disabled={!canEditProjectDetails}
            disableClearable
          />
          <EditIcon
            disabled={!canEditProjectDetails}
            style={{ cursor: 'pointer' }}
          />
          {/* </Tooltip> */}
        </Row>

        {!!workOrder?.proposal_tabs?.[0]?.id && (
          <NotesWrapper>
            <Notes
              label='Work order notes'
              route={`proposal-tabs/${workOrder?.proposal_tabs?.[0]?.id}/notes`}
            />
          </NotesWrapper>
        )}
      </Wrapper>
    </ErrorBoundary>
  )
}

const NotesWrapper = styled.div`
  margin-top: 16px;
`

const Wrapper = styled.div`
  max-width: 450px;

  .input-with-padding {
    padding-right: 20px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input,
  span {
    font-size: 14px;
    padding: 0 !important;
    background: none !important;
    border: none;
  }

  /* .ant-input, */
  .ant-select-selector,
  fieldset,
  .MuiAutocomplete-root,
  .ant-picker {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  .MuiAutocomplete-input {
    padding-right: 20px !important;
    margin-top: 2px;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select {
    top: -3px;
    height: 20px;
  }
  .orcatec-input {
    /* margin-left: -5px; */
    .MuiInputBase-root {
      padding-left: 0 !important;
    }
  }

  /*   .ant-select-selection-item {
    max-width: 75%;
  } */

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`
const Row = styled.div<{ disabled?: boolean }>`
  display: flex;
  position: relative;

  .MuiAutocomplete-input {
    color: ${props => (props.disabled ? '#898989' : 'initial')};
  }

  .ant-picker-suffix {
    color: ${props => (props.disabled ? '#bfbfbf' : '#4186f4')};
  }

  & > div {
    width: 100%;
  }
`
const Title = styled.p`
  color: #999999;
  flex: 0 0 40%;
`

const EditIcon = styled(EditOutlined)<{ disabled?: boolean }>`
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 15px;
  cursor: pointer;
  color: ${props => (props.disabled ? '#bfbfbf' : '#4186f4')};
  pointer-events: none;
`
