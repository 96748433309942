import { Tooltip } from 'antd'
import { EditProjectCode } from 'containers/MainContent/Orcatec/Proposals/components/Form/Header/components/EditProjectCode'
import { BackButtonOutlined } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { WOStatusInfo } from 'features/Project/components/WorkOrder/types'
import {
  deleteWorkOrder,
  selectWorkOrderSlice,
  updateWorkorderField,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { ProjectStatusDropdown } from 'features/Project/components/ProjectStatus/ProjectStatusDropdown'
import { Button } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { ProjectStatus } from 'features/Project/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { selectProject } from 'features/Project/projectSelectors'

interface Props {
  onGoBack: () => void
}

export const Heading = ({ onGoBack }: Props) => {
  const dispatch = useAppDispatch()

  const { current: workOrder, status } = useAppSelector(selectWorkOrderSlice)
  const project = useAppSelector(selectProject)

  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [deleteModal, showDeleteModal] = useState(false)

  const handleCodeSave = async (code: string) => {
    const res = await dispatch(updateWorkorderField({ code }))

    if (res?.payload?.errors?.code) throw new Error(res.payload.errors.code)

    return res
  }

  const handleStatusChange = async (status: ProjectStatus) => {
    setUpdatingStatus(true)

    await dispatch(
      updateWorkorderField({
        wo_status: status.id,
      }),
    )

    setUpdatingStatus(false)
  }

  const handleDeleteClick = () => showDeleteModal(true)

  const handleDeleteWorkOrder = async () => {
    const res = await dispatch(deleteWorkOrder(workOrder.id))

    if (res.meta.requestStatus === 'rejected') {
      return openNotificationWithIcon('error', {
        message: res?.payload?.message,
      })
    }

    showDeleteModal(false)
    onGoBack?.()
  }

  if (!workOrder) return null

  return (
    <Wrapper>
      <Tooltip title='Go back' mouseLeaveDelay={0}>
        <span onClick={onGoBack} style={{ height: 24 }}>
          <BackButtonOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
        </span>
      </Tooltip>

      <Title>Work order</Title>

      <EditProjectCode
        project={{
          ...workOrder,
          user_id: project.user_id,
          extraAssignedUsers: project.extraAssignedUsers,
        }}
        loading={status === 'updating'}
        onSave={handleCodeSave}
        disabled={false}
      />

      <ProjectStatusDropdown
        statusId={workOrder.wo_status}
        loading={updatingStatus}
        onChange={handleStatusChange}
        statusList={Object.values(WOStatusInfo)}
      />

      <StyledButton danger onClick={handleDeleteClick}>
        Delete work order
      </StyledButton>

      {deleteModal && (
        <Modal
          open
          title='Delete work order'
          onCancel={() => showDeleteModal(false)}
          onOk={handleDeleteWorkOrder}
          okText='Delete'
          confirmLoading={status === 'loading'}
        >
          <Text>Are you sure you want to delete the selected Work Order?</Text>
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
`

const Title = styled.p`
  font-size: 18px;
`

const StyledButton = styled(Button)`
  margin-left: auto;
`

const Text = styled.p`
  font-size: 16px;
`
