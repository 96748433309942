import TopBar from './TopBar'
import Footer from './Footer'
import SideBar from './Sidebar/v2/Sidebar'
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import './style.scss'
import Preloader from '../../containers/MainContent/Orcatec/components/Preloader'
import { isMobileDevise } from '../../helpers/isMobileDevice'
import withSubscription from '../../hoc/withSubscription'
import { compose } from 'redux'
import { Modal } from 'react-bootstrap'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from 'store/Orcatec/actions'
import { IconSubscriptionBtn } from './icons/IconSubscriptionBtn'
import ModalWrapper from '../../containers/MainContent/Orcatec/components/ModalWrapper/ModalWrapper'

import Card from '../../containers/MainContent/Orcatec/Settings/Subscription/Card'

import { updateSubscription } from '../../api/settings/Subscription'
import { notification, BackTop, Result, Button } from 'antd'
import Header from './Header'
import { useAppSelector } from '../../store/Orcatec/hooks'
import {
  getCompanySource,
  getCompanyTypes,
  getCompanyTechnicians,
} from 'store/Orcatec/operation/settings/company'
import { fetchQueueSettings } from 'features/Dispatch/queueSlice'
// import ReleaseModal from 'components/UI/ReleaseModal'
import { IHeaderType } from './Header/components/types'
import {
  updateCurrentPermission,
  setUserPermission,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { getProjectStatuses } from 'features/Project/slices/projectStatusSlice'

const Layout = props => {
  const location = useLocation()
  const dispatch = useDispatch()

  const isVerticalNavigation = useAppSelector(
    s =>
      s.orcatec.user?.me?.ui_settings?.header_view ===
      IHeaderType.HEADER_VERTICAL,
  )
  const modal =
    useSelector(state => state.orcatec.negativeBalance.show) &&
    props.subscriptionStatus !== 'INACTIVE'

  const [sidebar, changeSidebar] = useState(!isMobileDevise())
  const [isSubscriptionBtn, setIsSubscriptionBtn] = useState(false)
  const [isSubscription, setIsSubscription] = useState(
    document.body?.clientWidth > 1200,
  )
  // const [modal, setModal] = useState(false)

  // const scroll = useScrollDirection()
  // console.log(scroll)

  // useEffect(() => {
  // getConnector()
  // }, [])
  const scrollDirection = useAppSelector(
    state => state.orcatec.dispatchSlice.scrollDirection,
  )
  const toggleModal = () => {
    document.cookie = `id${props.me.id}=close`
    dispatch(actions.negativeBalance.toggleNegativeBalanceModal())
  }

  // const getCookie = name => {
  //   let matches = document.cookie.match(
  //     new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
  //   )
  //   return matches ? decodeURIComponent(matches[1]) : undefined
  // }

  const isNoSubscription = props.subscriptionStatus === 'INACTIVE'

  useEffect(() => {
    sidebar
      ? document.body.classList.remove('enlarged')
      : document.body.classList.add('enlarged')
  }, [sidebar])

  useEffect(() => {
    if (isNoSubscription) changeSidebar(false)
  }, [isNoSubscription])

  useEffect(() => {
    if (
      ['INACTIVE', 'CANCELLED'].includes(props.subscriptionStatus) &&
      !location?.pathname.includes('subscription') &&
      !!props.permissions?.current?.settings?.settings_can_read
    ) {
      props.history.push('/settings/subscription')
    }
  }, [location.pathname, isNoSubscription, props.permissions])

  useEffect(() => {
    if (!['PAID', 'CANCELLED'].includes(props.subscriptionStatus)) {
      toggleModal()
    }
  }, [props.subscriptionStatus])

  useEffect(() => {
    if (props.initialized) {
      dispatch(getCompanySource())
      dispatch(getCompanyTypes())
      dispatch(getCompanyTechnicians())
      dispatch(fetchQueueSettings())
      dispatch(getProjectStatuses())
    }
  }, [props.initialized])

  const toggleSidebar = flag => {
    if (!isNoSubscription) changeSidebar(flag)
  }

  const handleClickPay = () => {
    toggleModal()
    props.history.push('/settings/subscription')
  }

  const resizeListener = () => {
    setIsSubscriptionBtn(document.body?.clientWidth < 1201)
  }

  const showSubscription = delay => {
    setIsSubscription(true)
    setTimeout(() => {
      setIsSubscription(false)
    }, delay)
  }

  const getSubscriptionClassName = () => {
    return document.body?.clientWidth < 1201
      ? 'app-fail_subscription_message--show'
      : ''
  }

  const getSubscriptionBtnClassName = () => {
    return document.body?.clientWidth < 1201
      ? 'app-fail_subscription_button--hide'
      : ''
  }

  useEffect(() => {
    window.addEventListener('resize', resizeListener, false)
    window.addEventListener('load', resizeListener, false)
    return () => {
      window.removeEventListener('resize', resizeListener, false)
      window.addEventListener('load', resizeListener, false)
    }
  }, [document.body.clientHeight])

  /**
   * Payment Card Modal Part
   */

  const [visible, setVisible] = useState(false)

  const [errorCard, setErrorCard] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})
  const [modalStep, setModalStep] = useState(1)

  const parentEl = useRef(null)

  const takeCardTime = useSelector(
    state => state.orcatec?.user?.me?.take_card_time,
  )
  const subscriptionID = useSelector(
    state => state.orcatec?.company?.subscription?.id,
  )
  // const type = getValueFromLS('matrixSettings')
  useEffect(() => {
    if (takeCardTime) setVisible(true)
  }, [takeCardTime])

  // useEffect(() => {
  //   setPageView(type === 2 ? 'scroll' : 'inherit')
  // }, [type])

  const [dataCard, setDataCard] = useState({
    billing_address: {},
  })

  const validationCard = () => {
    const requiredText = 'This field is required'
    if (
      !dataCard.card_holder ||
      !dataCard.billing_address.region ||
      !dataCard.billing_address.city ||
      !dataCard.billing_address.address ||
      !dataCard.billing_address.postal ||
      !dataCard.token ||
      !dataCard.expiry
    ) {
      setFieldErrors(prev => ({
        ...prev,
        card_holder: !dataCard.card_holder ? requiredText : '',
        region: !dataCard.billing_address.region ? requiredText : '',
        city: !dataCard.billing_address.city ? requiredText : '',
        address: !dataCard.billing_address.address ? requiredText : '',
        postal: !dataCard.billing_address.postal ? requiredText : '',
        cvv: prev.cvv
          ? prev.cvv
          : !dataCard.token || !dataCard.expiry
          ? requiredText
          : '',
        expiry: prev.expiry
          ? prev.expiry
          : !dataCard.token || !dataCard.expiry
          ? requiredText
          : '',
        cardNumber: prev.cardNumber
          ? prev.cardNumber
          : !dataCard.token || !dataCard.expiry
          ? requiredText
          : '',
      }))
      setErrorCard(true)
    }
  }

  const handleCancel = () => {
    setVisible(false)
    setErrorCard(false)
    setFieldErrors({})
  }

  const handleUpdateCard = () => {
    if (modalStep === 1) {
      setModalStep(2)
    } else {
      if (visible) validationCard()
      if (!errorCard)
        updateSubscription(subscriptionID, dataCard)
          .then(() => setVisible(false))
          .then(() =>
            notification.success({
              message: 'Success!',
              description: 'Successfully updated!',
            }),
          )
          .catch(e =>
            notification.error({
              message: 'Error!',
              description: e.response?.data?.name,
            }),
          )
    }
  }

  useEffect(() => {
    const handleMessage = event => {
      const data = event.data
      if (!!data?.group) {
        dispatch(setUserPermission(event.data))
      }
      if (!!event?.data?.module_permissions) {
        dispatch(updateCurrentPermission(event.data.module_permissions))
      }
    }
    let channel = { onmessage: () => null, close: () => null }

    if (typeof BroadcastChannel !== 'undefined') {
      channel = new BroadcastChannel('permissionsChannel')
      channel.onmessage = handleMessage
    }

    return () => {
      channel.onmessage = null
      channel.close()
    }
  }, [])

  const isPageWithoutFooter =
    props.location.pathname === '/dispatch' ||
    props.location.pathname === '/jobs-list' ||
    props.location.pathname === '/estimates' ||
    props.location.pathname === '/projects' ||
    props.location.pathname === '/properties' ||
    props.location.pathname === '/clients' ||
    props.location.pathname === '/accounting' ||
    props.location.pathname === '/messaging' ||
    props.location.pathname === '/map' ||
    props.location.pathname === '/balances' ||
    props.location.pathname === '/progress' ||
    props.location.pathname === '/calls' ||
    props.location.pathname === '/expenses' ||
    props.location.pathname === '/custom-form' ||
    props.location.pathname === '/memberships' ||
    props.location.pathname === '/memberships' ||
    props.location.pathname === '/settings/project' ||
    // props.location.pathname === '/proposal/preview' ||
    props.location.pathname === '/settings/users' ||
    props.location.pathname === '/to-do' ||
    props.location.pathname === '/time-cards' ||
    props.location.pathname === '/reviews' ||
    props.location.pathname === '/settings/email' ||
    props.location.pathname === '/emails'

  return (
    <>
      {!/\/auth/.test(props.location.pathname) &&
      !/\/login/.test(props.location.pathname) &&
      // !/\/signup/.test(props.location.pathname) &&
      !/\/reviews\/review/.test(props.location.pathname) &&
      !/\/404\//.test(props.location.pathname) &&
      window.location.pathname !== '/preview' &&
      !window.location.pathname.includes('/custom-forms') &&
      window.location.pathname !== '/public-appointments' &&
      window.location.pathname !== '/print-appointment' &&
      !window.location.pathname.includes('/public/events') &&
      props.location.pathname !== '/' ? (
        props.initialized ? (
          <main>
            <div id='wrapper'>
              {!!props.subscriptionMessage &&
                props.subscriptionStatus !== 'INACTIVE' && (
                  <>
                    <div
                      className={`app-fail_subscription_message ${
                        isSubscription
                          ? getSubscriptionClassName()
                          : 'app-fail_subscription_message--hide'
                      }`}
                    >
                      <button
                        className='app-fail_subscription_close_btn'
                        onClick={() => setIsSubscription(false)}
                      >
                        <i className='mdi mdi-close' />
                      </button>
                      <div>{props.subscriptionMessage}</div>
                      <div>
                        <span>{props.daysLeft}</span>
                        <span>days left</span>
                      </div>
                      <Button
                        onClick={() =>
                          props.history.push('/settings/subscription')
                        }
                      >
                        My Subscription
                      </Button>
                    </div>
                    {!!isSubscriptionBtn && (
                      <button
                        onClick={() => showSubscription(5000)}
                        className={`app-fail_subscription_button ${
                          isSubscription ? getSubscriptionBtnClassName() : ''
                        }`}
                      >
                        <i>
                          <IconSubscriptionBtn />
                        </i>
                      </button>
                    )}
                  </>
                )}

              {!isVerticalNavigation ? (
                <div
                  className={`${
                    props.location.pathname === '/dispatch'
                      ? scrollDirection === 'down'
                        ? 'hide-header matrix__header'
                        : 'show-header matrix__header'
                      : ''
                  }`}
                >
                  <Header />
                </div>
              ) : (
                <>
                  <TopBar
                    toggle={toggleSidebar}
                    isOpen={sidebar}
                    location={props.location}
                    history={props.history}
                    me={props.me}
                    justButton={true}
                    currentPage={props.location.pathname}
                    isSidebarOpen={sidebar}
                  />

                  {!isNoSubscription && (
                    <SideBar
                      isOpen={sidebar}
                      toggle={toggleSidebar}
                      me={props.me}
                      location={props.location}
                      history={props.history}
                    />
                  )}
                </>
              )}

              {/* <ReleaseModal /> */}

              {/* <WelcomeModal /> */}

              <Modal
                show={modal /*  && !getCookie(`id${props.me.id}`) */}
                onHide={toggleModal}
                backdrop='static'
                centered
                contentClassName='subscription-modal'
              >
                <Modal.Header closeButton>
                  {/* <h4>{!clientId ? 'Add new Client' : `Edit client${currentName ? ` - ${currentName}` : ''}`}</h4>
                  header */}
                </Modal.Header>
                <Modal.Body>
                  {props.subscriptionMessage}
                  <p className='additional-inforation'>
                    If the payment is not received within{' '}
                    <span>{props.daysLeft}</span> days your account will be{' '}
                    {props.subscriptionStatus === 'VIEW_ONLY'
                      ? 'blocked.'
                      : ' switched to "inactive" mode, restricting access to viewing, creating, and editing data.'}
                  </p>
                  <div className='pay-button'>
                    <MainButton title='Pay Now' onClick={handleClickPay} />
                  </div>
                </Modal.Body>
              </Modal>
              <div
                className='content-page'
                style={{
                  marginLeft: isVerticalNavigation ? 80 : 0,
                  overflow:
                    props.location.pathname !== '/dispatch'
                      ? isVerticalNavigation
                        ? 'auto'
                        : 'initial'
                      : 'inherit',
                  // height: '100vh',
                  height:
                    props.location.pathname === '/map'
                      ? '100%'
                      : scrollDirection === 'down' || isVerticalNavigation
                      ? '100vh'
                      : props.location.pathname === '/dispatch'
                      ? '100vh'
                      : '100%',
                  minHeight:
                    props.location.pathname === '/map'
                      ? '100%'
                      : scrollDirection === 'down' || isVerticalNavigation
                      ? '100vh'
                      : props.location.pathname === '/dispatch'
                      ? '100vh'
                      : 'calc(100vh - 75px)',
                }}
              >
                <div
                  className={'content' + (!sidebar ? ' sidebar-closed' : '')}
                  style={{
                    marginBottom: isPageWithoutFooter ? 0 : 60,
                    paddingBottom: !isPageWithoutFooter ? 60 : 0,
                    marginTop:
                      props.location.pathname === '/dispatch' &&
                      isVerticalNavigation &&
                      scrollDirection === 'down'
                        ? 0
                        : isVerticalNavigation
                        ? 80
                        : 0,
                  }}
                >
                  <BackTop visibilityHeight={150} />
                  {props.shouldShowContent ? (
                    props.children
                  ) : (
                    <Result
                      status='403'
                      title={props.shouldShowPaidContentText}
                    />
                  )}
                </div>
                {!isPageWithoutFooter && <Footer />}
              </div>
            </div>
            <div className='card-container'>
              <ModalWrapper
                modal={visible}
                close={handleCancel}
                apply={handleUpdateCard}
                okText={modalStep === 1 ? 'Update' : 'Submit'}
                // isSaving={isFetching}
                dialogClassName={`${
                  modalStep !== 1
                    ? 'pay_transaction_modal'
                    : 'before-charge-card-modal'
                }`}
                disableOkBtn={
                  modalStep === 1
                    ? false
                    : errorCard || Object.keys(dataCard).length <= 1
                }
                footer={modalStep === 1 ? null : undefined}
                withoutCancelBtn={modalStep === 1}
              >
                {modalStep === 1 ? (
                  <div className={'before-charge-card-modal step-1'}>
                    <p className={'text-main'}>
                      Your trial is about to expire. Please update your payment
                      information.
                    </p>
                  </div>
                ) : (
                  <Card
                    visible
                    cardData={dataCard}
                    parentEl={parentEl}
                    error={errorCard}
                    setData={setDataCard}
                    setError={setErrorCard}
                    fieldErrors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                  />
                )}
              </ModalWrapper>
            </div>
          </main>
        ) : (
          <Preloader />
        )
      ) : (
        props.children
      )}
    </>
  )
}

export default compose(withRouter, withSubscription)(Layout)
