import React, { useState, useEffect } from 'react'
import makeEmailTemplate from './components/makeEmailTemplate'
import * as selectors from '../../../../../../../store/Orcatec/selectors/proposal/proposal-form.jsx'
import { Editor } from 'components/UI/Editor/Editor'
// import { Editor } from '@tinymce/tinymce-react'

// import { Form, InputGroup, FormControl } from 'react-bootstrap'
import * as actions from 'store/Orcatec/actions'
import { useDispatch, useSelector } from 'react-redux'
import MainButton from '../../../../components/buttons/MainButton'
import { CheckOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { Checkbox, Divider, Empty, Input } from 'antd'
import ModuleStatus from '../../ModuleStatus'
import Notes from '../../../../components/Notes'
import './ModalOrderEquipment.scss'
import { fetchOrdersHistory } from 'api/Proposal'
import EmailHistory from './components/EmailHistory'
import SectionItems from './components/SectionItems'
import validator from 'validator'
import { IinitialState, IHistory, IStatusList } from './types'
import CustomSupplies from './components/CustomSupplies'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectRelatedJobs } from 'store/Orcatec/reducers/jobs'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import {
  selectProject,
  selectProjectActiveTab,
  selectProjectMaterialsItemWithSection,
  // selectProjectSections,
} from 'features/Project/projectSelectors'

const statusList: IStatusList[] = [
  {
    id: 1,
    name: 'Nothing to Order',
  },
  {
    id: 2,
    name: 'Need To Order',
  },
  {
    id: 3,
    name: 'Ordered',
  },
  {
    id: 4,
    name: 'Partially Received',
  },
  {
    id: 5,
    name: 'Received',
  },
]

const initialState = {
  isSaveNeeded: false,
  isSendingEmail: false,
  isFetchingHistory: false,
  isStatusReseting: false,
  isSaving: false,
  step: 1,
  checkedSupplier: '',
  choosenSuppliersEmails: [],
  customSuppliersEmails: [],
  customEmails: false,
  checkedEquipment: [],
  checkedParts: [],
  checkedSupplies: [],
  mailtext: '',
  history: [],
  isAllOrdered: false,
  noItems: false,
  status: 1,
}

export interface CustomSupply {
  description: string
  qty: number
}

interface Props {
  onStatusChanged?: (status: number) => void
  onClickSave?: () => void
  isJob?: boolean
  status: number
  jobTabId?: number | null
}

const ModalOrderEquipment = ({
  onStatusChanged,
  onClickSave,
  status,
  jobTabId = null,
  isJob,
  jobProject,
}: Props) => {
  const dispatch = useDispatch()
  const project = isJob ? jobProject : useAppSelector(selectProject)
  // const proposalSections = useAppSelector(selectProjectSections)
  const currentTab = isJob
    ? jobProject?.proposal_tabs?.[0]
    : useAppSelector(selectProjectActiveTab)

  const [state, setState] = useState<IinitialState>({ ...initialState, status })
  const [showHistory, setShowHistory] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [customSupplies, setCustomSupplies] = useState<CustomSupply[]>([])

  // const modalEquipment = useSelector(selectors.modalEquipment)
  const suppliers = useSelector(selectors.suppliers)
  const me = useSelector(selectors.me)
  const proposalCode = isJob
    ? jobProject.code
    : useSelector(selectors.proposalCode)
  // const proposalSections = useSelector(selectors.proposalSections)
  const materialItems = isJob
    ? jobProject?.proposal_tabs?.[0]?.proposal_sections?.map(section => ({
        section_title: section.title,
        items: section.items,
      }))
    : useAppSelector(selectProjectMaterialsItemWithSection)

  const tabId = isJob ? jobTabId : currentTab.id
  const noItems = !materialItems?.length
  const { relatedJobs } = useAppSelector(selectRelatedJobs)

  const getfetchSuppliersList = () => {
    return dispatch(actions.supplier.fetchAllSuppliers())
  }
  const openModal = () => {
    setState(initialState)
    setEmailError(false)
    setShowHistory(false)
    setCustomSupplies([])
    return dispatch(actions.modalEquipment.openModal())
  }

  const toggleShowHistory = () => {
    setShowHistory(prev => !prev)
  }

  const changeOrderEquipmentStatus = (status: number) => {
    // return dispatch(actions.proposalForm.changeOrderEquipmentStatus(status))
    return dispatch(
      updateProjectTabField({
        status,
      }),
    )
  }

  const onChangeSection = (value, section, item, item_type, field) => {
    return dispatch(
      actions.proposalForm.onChangeSection(
        value,
        section,
        item,
        item_type,
        field,
      ),
    )
  }

  const orderEquipment = (data: IHistory) => {
    return dispatch(actions.proposalForm.orderEquipment(data))
  }

  const getOrdersHistory = tabId => {
    return dispatch(actions.modalEquipment.getOrdersHistory(tabId))
  }

  const nextStep = () => {
    let mailtext: null | string = null
    if (state.step === 2) {
      mailtext = makeEmailTemplate(
        state,
        materialItems,
        // proposalForm,
        proposalCode,
        me,
        customSupplies,
        relatedJobs,
      )
    }
    setState(prev => ({ ...prev, step: prev.step + 1, mailtext }))
  }

  const previousStep = () => {
    setState(prev => ({ ...prev, step: prev.step - 1 }))
  }

  const sendOrder = () => {
    if (
      // (state.checkedEquipment?.length ||
      //   state.checkedParts?.length ||
      //   state.checkedSupplies?.length ||
      //   customSupplies?.length) &&
      state.choosenSuppliersEmails?.length ||
      state.customSuppliersEmails?.length
    ) {
      setState(prev => ({ ...prev, isSendingEmail: true }))
      changeOrderEquipmentStatus(3)

      onStatusChanged?.(3)
      onClickSave?.(3)

      const data: IHistory = {
        proposal_tab_id: tabId,
        username: me.full_name,
        body: [state.mailtext],
        emails: state.choosenSuppliersEmails.concat(
          state.customSuppliersEmails,
        ),
      }
      orderEquipment(data)
      openModal()
      setState({ ...initialState, isSendingEmail: false, status: 3 })
    }
  }

  const handleEditorChange = content => {
    return setState(prev => ({ ...prev, mailtext: content }))
  }

  const onChangeSupplier = event => {
    const { value } = event.target
    if (state?.choosenSuppliersEmails?.includes(value)) {
      setState(prev => ({
        ...prev,
        choosenSuppliersEmails: state?.choosenSuppliersEmails.filter(
          email => email !== value,
        ),
      }))
      return
    }
    setState(prev => ({
      ...prev,
      choosenSuppliersEmails: [...prev.choosenSuppliersEmails, value],
    }))
  }

  const onChangeItems = (e, section, itemNum, itemsName) => {
    const item = e.target.value
    const checkedItems = [...state[`checked${itemsName}`]]
    const idIndex = checkedItems.findIndex(
      item => item.section === section && item.itemNum === itemNum,
    )
    if (idIndex < 0) {
      checkedItems.push({ item, section, itemNum })
    } else {
      checkedItems.splice(idIndex, 1)
    }
    setState(prev => ({ ...prev, [`checked${itemsName}`]: checkedItems }))
  }

  const onSave = async () => {
    setState(prev => ({ ...prev, isSaving: true }))

    await changeOrderEquipmentStatus(state.status)

    openModal()
    setState(prev => ({ ...prev, isSaving: false }))

    onClickSave?.(state.status)

    setCustomSupplies([])
  }

  const onChangeCustomEmails = e => {
    const value = e.target.value.trim()

    if (!value && emailError) {
      setState(prev => ({
        ...prev,
        customSuppliersEmails: [],
      }))

      return setEmailError(false)
    }

    if (!validator.isEmail(value)) {
      setState(prev => ({
        ...prev,
        customSuppliersEmails: value.replace(/\s+/g, '').split(','),
      }))
      return setEmailError(true)
    }

    setEmailError(false)
    setState(prev => ({
      ...prev,
      customSuppliersEmails: value.replace(/\s+/g, '').split(','),
    }))
  }

  const handleChangeStatus = status => {
    if (project.status === 3) return
    setState(prev => ({ ...prev, status, isSaveNeeded: true }))
    onStatusChanged?.(status)
  }
  const customEmailHeandler = () => {
    setEmailError(false)
    setState(prev => ({
      ...prev,
      customEmails: !state.customEmails,
      customSuppliersEmails: [],
    }))
  }

  const blockedBodyScroll = (isBlocking = false) => {
    const x = document.querySelector('body')
    if (isBlocking) {
      return x.setAttribute('style', 'overflow: hidden !important')
    }
    return x.removeAttribute('style')
  }

  const modalTitle = (
    <>
      {state.step !== 1 ? (
        <>
          <LeftOutlined onClick={previousStep} />
          {` `}
        </>
      ) : (
        ''
      )}
      {state.step === 1
        ? 'Supplies'
        : `Supplies Order (Step ${state.step - 1})`}
      <CloseOutlined
        onClick={() => {
          openModal()
          setState(prev => ({
            ...prev,
            isSaveNeeded: false,
            isAllOrdered: currentTab.status === 3,
          }))
        }}
      />
    </>
  )

  const modalFooter = (() => {
    const hasEmails =
      !!state.choosenSuppliersEmails.length ||
      (!!state.customSuppliersEmails.length && !emailError)
    if (state.step === 2)
      return (
        <MainButton
          onClick={nextStep}
          className='waves-effect waves-light'
          disabled={!hasEmails}
          title='Review Order'
        />
      )
    if (state.step === 3)
      return (
        <MainButton
          title='Send Email'
          className='waves-effect waves-light'
          onClick={sendOrder}
          isFetching={state.isSendingEmail}
        >
          Send Email
        </MainButton>
      )
  })()

  useEffect(() => {
    getfetchSuppliersList()
    fetchOrdersHistory(tabId).then(data =>
      setState(prev => ({
        ...prev,
        history: data,
        isAllOrdered: currentTab.status === 3,
        status: currentTab.status,
      })),
    )
  }, [])

  useEffect(() => {
    if (state.step === 1 /* && modalEquipment.isOpen */) {
      getOrdersHistory(tabId).then(data => {
        setState(prev => ({
          ...prev,
          history: data,
          isFetchingHistory: false,
          status: currentTab.status,
          isAllOrdered: currentTab.status === 3,
        }))
      })
    }
  }, [/* modalEquipment.isOpen, */ state.step])

  useEffect(() => {
    blockedBodyScroll(true)

    return () => {
      blockedBodyScroll()
    }
  }, [])

  const handleAddCustomSupply = () => {
    setCustomSupplies(prev => [
      ...prev,
      {
        description: '',
        qty: 1,
      },
    ])
  }

  const handleChangeCustomSupply = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target

    const newItems = [...customSupplies]
    newItems[index] = {
      ...newItems[index],
      [name]: value,
    }
    setCustomSupplies(newItems)
  }

  const handleDeleteCustomSupply = (index: number) => {
    setCustomSupplies(prev => prev?.filter((el, i) => i !== index))
  }

  return (
    <Modal
      visible
      closable={false}
      wrapClassName='order-equipment-modal'
      maskClosable={false}
      title={modalTitle}
      footer={state.step === 1 ? null : [modalFooter]}
      onCancel={openModal}
      width={700}
      getContainer={false}
      destroyOnClose
    >
      {state.step === 1 && (
        <>
          <ModuleStatus
            statusList={statusList}
            currentStatus={state?.status}
            onChangeStatus={handleChangeStatus}
          />
          <Notes
            customBtnStyle={{
              position: 'absolute',
              bottom: project.status !== 3 ? '-54px' : '-32px',
              left: project.status !== 3 ? '0px' : '80%',
            }}
            route={`proposals/${project.id}/notes`}
          />
          {project.status !== 3 && (
            <>
              <div className='d-flex justify-content-end align-items-center mb-4 mt-4'>
                <div className='d-flex'>
                  <MainButton
                    className='waves-effect waves-light mr-2'
                    title='Order Supplies'
                    onClick={nextStep}
                  />

                  <MainButton
                    title={showHistory ? 'Hide history' : 'Show history'}
                    className='waves-effect waves-light mr-2'
                    onClick={toggleShowHistory}
                    disabled={state.history.length === 0}
                  />
                  <MainButton
                    title='Save'
                    className='waves-effect waves-light mr-2'
                    onClick={onSave}
                    disabled={!state.isSaveNeeded}
                    isFetching={state.isSaving}
                  />
                </div>
              </div>
            </>
          )}
          {showHistory && (
            <>
              <Divider>Order history</Divider>
              <div className='email-history'>
                <EmailHistory history={state.history} />
              </div>
            </>
          )}
        </>
      )}

      {state.step === 2 && (
        <>
          <Divider>Select items you want to order</Divider>
          <div className='d-flex flex-column' id='equipments'>
            {noItems ? (
              <Empty description='Please add some equipment or parts or supplies to proposal' />
            ) : (
              <SectionItems
                proposalSections={materialItems}
                onChangeItems={onChangeItems}
                onChangeSection={onChangeSection}
                state={state}
              />
            )}
          </div>

          {/* {isJob && ( */}
          <CustomSupplies
            data={customSupplies}
            onChange={handleChangeCustomSupply}
            onAddNew={handleAddCustomSupply}
            onDelete={handleDeleteCustomSupply}
          />
          {/* )} */}

          <Divider>Distributor</Divider>
          <div className='distributors' id='distributor'>
            {suppliers?.data?.map(({ name, email }, idx) => (
              <Checkbox
                checked={state?.choosenSuppliersEmails?.includes(email)}
                onChange={onChangeSupplier}
                style={{ margin: '0' }}
                value={email}
                key={idx}
              >
                {`${name} (${email})`}
              </Checkbox>
            ))}
            <div className='input-email_wrapper'>
              <Checkbox
                checked={state.customEmails}
                onChange={customEmailHeandler}
              ></Checkbox>

              {/* <InputGroup size='sm' style={{ width: '250px' }}>
                <FormControl
                  value={state.customSuppliersEmails}
                  onChange={onChangeCustomEmails}
                  disabled={!state.customEmails}
                  placeholder='Enter your email'
                  isInvalid={emailError}
                  isValid={!emailError && emailError !== null}
                />
                {emailError && (
                  <Form.Control.Feedback
                    style={{ display: 'flex', justifyContent: 'center' }}
                    type='invalid'
                  >
                    This email is incorrect.
                  </Form.Control.Feedback>
                )}
              </InputGroup> */}

              <div style={{ position: 'relative' }}>
                <Input
                  value={state.customSuppliersEmails}
                  onChange={onChangeCustomEmails}
                  disabled={!state.customEmails}
                  placeholder='Enter your email'
                  status={emailError ? 'error' : undefined}
                  style={{ maxWidth: 300 }}
                  suffix={
                    !state.customSuppliersEmails.length ? (
                      undefined
                    ) : emailError ? (
                      <CloseOutlined style={{ color: 'red' }} />
                    ) : (
                      <CheckOutlined style={{ color: 'green' }} />
                    )
                  }
                />
                {emailError && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: 10,
                      position: 'absolute',
                      bottom: -20,
                      left: 0,
                    }}
                  >
                    Incorect email
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {state.step === 3 && (
        <>
          Email to be sent:{' '}
          {(state.customEmails
            ? [...state.choosenSuppliersEmails, ...state.customSuppliersEmails]
            : state.choosenSuppliersEmails
          ).join(', ')}
          <Editor value={state.mailtext} onEditorChange={handleEditorChange} />
          {/* <Editor
            apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
            value={state.mailtext}
            init={{
              height: 350,
              plugins:
                'directionality image link media template hr anchor toc lists emoticons',
              content_style: 'body { font-size:14px }',
              menubar: false,
              toolbar:
                'fontselect formatselect | bold italic strikethrough forecolor backcolor formatpainter| alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent',
            }}
            onEditorChange={handleEditorChange}
          /> */}
        </>
      )}
    </Modal>
  )
}

export default ModalOrderEquipment
