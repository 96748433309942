import { Checkbox, Collapse, Spin, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ContactI, ContactWithMessage } from '../../types'
import { HeadInfo } from '../HeadInfo/HeadInfo'
import { IExistingPhone } from 'features/Messaging/types'
import { Select } from 'components/UIKit'
import { ITemplate, NotificationModalType } from 'features/Notification/types'
import { useNotificationTemplate } from 'features/Notification/hooks/useNotificationTemplate'
import { parseEmailMessage } from 'features/Notification/helpers/parseEmailMessasge'
import { User } from 'types/User'
import { Company } from 'features/Settings/Subscription/types'
import { Project, ProjectItem, ProjectProperty } from 'features/Project/types'
import { Job } from 'types/Jobs'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { Recipients } from '../../NotificationModalV2'

const { Panel } = Collapse

interface Props {
  data: ContactI[]
  selectedContacts: Recipients
  industryId: number
  companyPhones: IExistingPhone[]
  title: string
  templatesList: (ITemplate & {
    label: string
  })[]
  notificationType: NotificationModalType
  onChange: (selectedContacts: Recipients) => void
  me: User
  company: Company
  scopeOfWork: ProjectItem[]
  workOrder: Project
  property: ProjectProperty[]
  event: Job
}

export const ContactsList = ({
  data,
  notificationType,
  industryId,
  selectedContacts,
  title,
  companyPhones,
  onChange,
  templatesList,
  me,
  company,
  scopeOfWork,
  workOrder,
  property,
  event,
}: Props) => {
  const disabled = !companyPhones?.length
  const { recipients, ...emailData } = selectedContacts || {}

  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null)

  const {
    notificationTemplate,
    loading: templateLoading,
  } = useNotificationTemplate(notificationType, industryId, selectedTemplate)

  const { body: emailMessage, from_name, reply_to, subject } =
    notificationTemplate?.email ?? {}
  const phoneMessage = notificationTemplate?.sms?.body || ''

  const parseMessageWithSourceData = (contact?: ContactI) => (
    message: string | undefined,
    type: 'sms' | 'email',
  ) => {
    return parseEmailMessage(message || '', type, {
      event: {
        ...event,
        tech: contact,
        proposal_info: { ...workOrder, properties: property },
      },
      authUser: me,
      company: company,
      scopeOfWork: scopeOfWork,
    })
  }

  useEffect(() => {
    if (!selectedTemplate && notificationTemplate?.id) {
      setSelectedTemplate(notificationTemplate?.id)
    }

    const contactsToUpdate = Object?.values(recipients).map(contact => ({
      ...contact,
      emailMessage: parseMessageWithSourceData(contact)(emailMessage, 'email'),
      phoneMessage: parseMessageWithSourceData(contact)(phoneMessage, 'sms'),
    }))

    onChange({
      from_name: parseMessageWithSourceData()(from_name, 'email'),
      reply_to: parseMessageWithSourceData()(reply_to, 'email'),
      subject: parseMessageWithSourceData()(subject, 'email')?.replaceAll(
        '<br/>',
        ' ',
      ),
      from_number: companyPhones?.[0]?.number || '',
      recipients: transformArrayToObj(contactsToUpdate),
    })
  }, [notificationTemplate?.id, companyPhones?.length])

  useEffect(() => {
    setCheckAll(areAllSelected)
  }, [selectedContacts])

  const handleHeadInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    onChange({
      ...selectedContacts,
      [name]: value,
    })
  }
  const [checkAll, setCheckAll] = useState(false)

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target

    const body = checked
      ? transformArrayToObj(
          data.map(contact => ({
            ...contact,
            phones: companyPhones?.length ? contact?.phones || [] : [],
            emailMessage: parseMessageWithSourceData(contact)(
              emailMessage,
              'email',
            ),
            phoneMessage: parseMessageWithSourceData(contact)(
              phoneMessage,
              'sms',
            ),
          })),
        )
      : {}

    onChange({ ...selectedContacts, recipients: body })
  }

  const handleTemplateSelect = (_: Event, templateId: number) =>
    setSelectedTemplate(templateId)

  const handleContactSelect = (
    type: 'emails' | 'phones',
    value: string,
    contact: ContactI,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { phones, emails, ...rest } = contact
    const { id } = contact

    const helperFunc = (prev: Record<number, ContactWithMessage>) => ({
      ...prev,
      [id]: {
        ...rest,
        ...prev[id],
        [type]: prev?.[id]?.[type]?.includes(value)
          ? prev[id][type].filter(e => e !== value)
          : [...(prev?.[id]?.[type] || []), value],
        emailMessage: parseMessageWithSourceData(contact)(
          emailMessage,
          'email',
        ),
        phoneMessage: parseMessageWithSourceData(contact)(phoneMessage, 'sms'),
      },
    })

    onChange({
      ...selectedContacts,
      recipients: helperFunc(recipients),
    })
  }

  const contactsEmails = data.flatMap(contact => contact.emails || [])
  const contactsPhones = data.flatMap(contact =>
    disabled ? [] : contact.phones || [],
  )
  const selectedContactsPhones = Object.values(
    selectedContacts.recipients,
  ).flatMap(contact => contact.phones || [])
  const selectedContactsEmails = Object.values(
    selectedContacts.recipients,
  ).flatMap(contact => contact.emails || [])
  const areAllSelected =
    selectedContactsEmails.length + selectedContactsPhones.length ===
    contactsEmails.length + contactsPhones.length

  return (
    <Spin spinning={templateLoading}>
      <Wrapper>
        <Title>{title}:</Title>

        <Content>
          <Section>
            <Template>
              <Name>Template</Name>

              <Select
                name='template'
                value={selectedTemplate}
                onChange={handleTemplateSelect}
                style={{ width: '100%' }}
                options={templatesList}
              />
            </Template>
          </Section>

          <Heading>
            <p></p>
            <Checkbox
              indeterminate={
                !checkAll &&
                !![...selectedContactsPhones, ...selectedContactsEmails].length
              }
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
            </Checkbox>
          </Heading>

          <Section>
            <List>
              {data?.map(contact => (
                <Item key={contact.id}>
                  <Name>{contact.name}</Name>

                  <Data>
                    {contact.emails?.map((email, idx) => (
                      <DataItem key={email + idx}>
                        <StyledCheckbox
                          checked={selectedContacts?.recipients?.[
                            contact?.id
                          ]?.emails?.includes(email)}
                          onChange={() =>
                            handleContactSelect('emails', email, contact)
                          }
                        />
                        <Text color='#3f57ac'>{email}</Text>
                      </DataItem>
                    ))}
                    {contact.phones?.map((phone, idx) => (
                      <DataItem key={phone + idx + contact.id}>
                        <Tooltip
                          title={
                            disabled ? 'Messaging service isn`t setup yet' : ''
                          }
                          mouseLeaveDelay={0}
                        >
                          <StyledCheckbox
                            checked={selectedContacts?.recipients?.[
                              contact?.id
                            ]?.phones?.includes(phone)}
                            onChange={() =>
                              handleContactSelect('phones', phone, contact)
                            }
                            disabled={disabled}
                          />
                          <Text>{phone}</Text>
                        </Tooltip>
                      </DataItem>
                    ))}
                  </Data>
                </Item>
              ))}
            </List>
          </Section>

          <Collapse style={{ marginLeft: '-15px' }}>
            <Panel header={'Messages settings'} key='1'>
              <HeadInfo
                data={emailData}
                onChange={handleHeadInfoChange}
                phones={companyPhones}
              />
            </Panel>
          </Collapse>
        </Content>
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div``
const Content = styled.div`
  padding-left: 15px;
`
const Section = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
`

const Heading = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  align-items: center;
`
const Title = styled.p`
  /* margin-top: 10px; */
  font-size: 18px;
  color: ${props => props.theme.font.secondary};
  font-weight: 700;
`
const List = styled.div`
  /* margin-left: 10px; */
`
const Item = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  margin-bottom: 10px;
`
const Data = styled.div``
const Name = styled.p`
  font-weight: 500;
`
const DataItem = styled.p`
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
`

const Text = styled.span<{ color?: string }>`
  color: ${props => props.color || props.theme.font.main};
`

const StyledCheckbox = styled(Checkbox)<{ error?: boolean }>`
  .ant-checkbox-inner {
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
  }
`
const Template = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  align-items: center;
`
