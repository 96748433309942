import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { DispatchEvent } from 'features/Dispatch/types'
import { EventGroupAPI } from 'features/Visits/api'
import { useCallback, useEffect, useState } from 'react'

export const useGroupEvents = (
  groupId: number,
  params: GeneralTableParams,
  revalidate?: number,
) => {
  const [events, setEvents] = useState<DispatchEvent[]>([])
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    total: 10,
  })
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    if (!groupId) return

    setLoading(true)

    try {
      const res = await EventGroupAPI.getGroupEvents(groupId, params)

      setEvents(res.data)
      setPagination({
        current_page: res.meta.current_page,
        per_page: res.meta.per_page,
        total: res.meta.total,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [groupId, params?.page, params?.per_page])

  useEffect(() => {
    fetchData()
  }, [fetchData, revalidate])

  return { events, loading, pagination }
}
