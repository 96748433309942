import { Checkbox, Collapse, Spin, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { TextField, Select } from 'components/UIKit'
import { IExistingPhone } from 'features/Messaging/types'
import { useNotificationTemplate } from 'features/Notification/hooks/useNotificationTemplate'
import { ITemplate, NotificationModalType } from 'features/Notification/types'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'
import validator from 'validator'
import { HeadInfo } from '../HeadInfo/HeadInfo'
import { OtherContacts as IOtherContacts } from '../../NotificationModalV2'
import { parseEmailMessage } from 'features/Notification/helpers/parseEmailMessasge'
import { ContactI } from '../../types'
import { User } from 'types/User'
import { Company } from 'features/Settings/Subscription/types'
import { Project, ProjectItem, ProjectProperty } from 'features/Project/types'
import { Job } from 'types/Jobs'

const { Panel } = Collapse

interface Props {
  // disabled: boolean
  // error: boolean
  // emails: {
  //   checked: boolean
  //   value: string[]
  // }
  // phone: {
  //   checked: boolean
  //   value: string
  // }
  otherContacts: IOtherContacts
  industryId: number
  companyPhones: IExistingPhone[]
  templatesList: (ITemplate & {
    label: string
  })[]
  notificationType: NotificationModalType
  me: User
  company: Company
  scopeOfWork: ProjectItem[]
  workOrder: Project
  property: ProjectProperty[]
  event: Job
  onChange: (otherContacts: IOtherContacts) => void
  // onCheck: (type: 'emails' | 'phone', value: boolean) => void
}

export const OtherContacts = ({
  otherContacts,
  companyPhones,
  templatesList,
  notificationType,
  industryId,
  event,
  me,
  company,
  scopeOfWork,
  property,
  workOrder,
  onChange,
}: Props) => {
  const disabled = !companyPhones?.length
  const { phone, emails, ...emailData } = otherContacts || {}

  const [error, setError] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null)

  const parseMessageWithSourceData = (contact?: ContactI) => (
    message: string | undefined,
    type: 'sms' | 'email',
  ) => {
    return parseEmailMessage(message || '', type, {
      event: {
        ...event,
        tech: contact,
        proposal_info: { ...workOrder, properties: property },
      },
      authUser: me,
      company: company,
      scopeOfWork: scopeOfWork,
    })
  }

  const {
    notificationTemplate,
    loading: templateLoading,
  } = useNotificationTemplate(notificationType, industryId, selectedTemplate)

  const { body: emailMessage, from_name, reply_to, subject } =
    notificationTemplate?.email ?? {}
  const phoneMessage = notificationTemplate?.sms?.body || ''

  useEffect(() => {
    if (!selectedTemplate && notificationTemplate?.id) {
      setSelectedTemplate(notificationTemplate?.id)
    }

    // const contactsToUpdate = Object?.values(recipients).map(contact => ({
    //   ...contact,
    //   emailMessage: parseMessageWithSourceData(contact)(emailMessage, 'email'),
    //   phoneMessage: parseMessageWithSourceData(contact)(phoneMessage, 'sms'),
    // }))

    onChange({
      ...otherContacts,
      from_name: parseMessageWithSourceData()(from_name, 'email'),
      reply_to: parseMessageWithSourceData()(reply_to, 'email'),
      subject: parseMessageWithSourceData()(subject, 'email')?.replaceAll(
        '<br/>',
        ' ',
      ),
      from_number: companyPhones?.[0]?.number || '',
      emailMessage: parseMessageWithSourceData()(emailMessage, 'email'),
      phoneMessage: parseMessageWithSourceData()(phoneMessage, 'sms'),
    })
  }, [notificationTemplate?.id])

  // const handlePhoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedCompanyPhone(e.target.value)
  // }

  const handleHeadInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    onChange({
      ...otherContacts,
      [name]: value,
    })
  }

  const handleTemplateSelect = (_: Event, templateId: number) =>
    setSelectedTemplate(templateId)

  const handleChangeEmail = (_: Event, value: string[]) => {
    const emailValues = value
      .map(email => email.trim())
      .filter(email => validator.isEmail(email))

    // onChange('emails', emailValues)

    onChange({
      ...otherContacts,
      emails: {
        checked: !!emailValues.length,
        value: emailValues,
      },
    })
  }
  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    onChange({
      ...otherContacts,
      phone: {
        checked: !value.includes('_'),
        value,
      },
    })

    // onChange('phone', value)

    setError(value.includes('_'))
  }

  const handleCheck = (type: 'emails' | 'phone') => (e: CheckboxChangeEvent) =>
    onChange({
      ...otherContacts,
      [type]: {
        ...otherContacts[type],
        checked: e.target.checked,
      },
    })
  // onCheck(type, e.target.checked)

  return (
    <Spin spinning={templateLoading}>
      <Wrapper>
        <MainTitle>Others:</MainTitle>

        <Content>
          <Template>
            <Title>Template</Title>

            <Select
              name='template'
              value={selectedTemplate}
              onChange={handleTemplateSelect}
              style={{ width: '100%' }}
              options={templatesList}
            />
          </Template>

          <Section>
            <Title>Other emails:</Title>
            <Row>
              <Checkbox
                checked={emails.checked}
                onChange={handleCheck('emails')}
              />
              <Select
                mode='tags'
                name='emails'
                value={emails.value}
                onChange={handleChangeEmail}
                // error={errorEmpty ? 'Enter valid email' : undefined}
                open={false}
              />
            </Row>
          </Section>

          <Section>
            <Title>Other phone:</Title>
            <Tooltip
              title={disabled ? 'Messaging service isn`t setup yet' : ''}
            >
              <Row>
                <Checkbox
                  checked={phone.checked}
                  onChange={handleCheck('phone')}
                  disabled={disabled}
                />
                <ReactInputMask
                  name='phone'
                  mask='(999) 999-9999'
                  value={phone.value || ''}
                  onChange={handleChangePhone}
                  disabled={disabled}
                >
                  {() => (
                    <TextField
                      disabled={disabled}
                      placeholder='(XXX) XXX-XXXX'
                      error={!!error && 'Enter valid phone number'}
                    />
                  )}
                </ReactInputMask>
              </Row>
            </Tooltip>
          </Section>

          <Collapse
            // defaultActiveKey={['1']}
            // ghost
            style={{ marginLeft: '-15px' }}
          >
            <Panel header={'Messages settings'} key='1'>
              <HeadInfo
                data={emailData}
                onChange={handleHeadInfoChange}
                phones={companyPhones}
              />
            </Panel>
          </Collapse>
        </Content>
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div``
const Section = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  margin-bottom: 16px;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const Title = styled.p`
  font-weight: 500;
`

const Content = styled.div`
  padding-left: 15px;
`

const MainTitle = styled.p`
  font-size: 18px;
  color: ${props => props.theme.font.secondary};
  font-weight: 700;
`

const Template = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
`
