import { Select, TextField } from 'components/UIKit'
import { IExistingPhone } from 'features/Messaging/types'
import { parsePhoneNumber } from 'features/Messaging/utils'
import styled from 'styled-components'
import { Recipients } from '../../NotificationModalV2'

interface Props {
  data: Omit<Recipients, 'recipients'>
  // companyPhone: string
  phones: IExistingPhone[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  // onPhoneChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const HeadInfo = ({
  data,
  onChange,
  // companyPhone,
  // onPhoneChange,
  phones,
}: Props) => {
  return (
    <Wrapper>
      <TextField
        value={data.from_name}
        onChange={onChange}
        label='Sender name*'
        name='from_name'
        // error={proposalViewErrors?.from_name}
        // disabled={loading}
      />

      <TextField
        value={data.reply_to}
        onChange={onChange}
        label='Reply to*'
        name='reply_to'
        // error={proposalViewErrors?.reply_to}
        // disabled={loading}
      />

      <TextField
        value={data.subject}
        onChange={onChange}
        label='Subject*'
        name='subject'
        // error={proposalViewErrors?.subject}
        // disabled={loading}
      />

      {!!phones.length && (
        <Select
          name='from_number'
          label='From number'
          value={data.from_number}
          onChange={onChange}
          // style={{ marginBottom: '10px', width: '100%' }}
          options={phones.map(({ number, alias = '' }) => ({
            value: number,
            label: `${alias ?? ''} ${parsePhoneNumber(number)}`,
          }))}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* padding-top: 10px; */
  /* border-top: 0.3px solid #19191921;
  border-bottom: 0.3px solid #19191921; */

  & > * {
    margin-bottom: 10px;
  }
`
