import axiosOrcatec from './axiosInstance'
import { INote } from '../containers/MainContent/Orcatec/components/Notes/types'
import axios from 'axios'

export const apiNotes = {
  getEntityNotes: (route: string) =>
    axiosOrcatec
      .get<{ data: INote[] }>(route)
      .then(r => r?.data?.data)
      .catch(e => console.error(e)),
  postEntityNote: (route: string, data: INote | { text: string }) => {
    let cancelToken = null
    if (cancelToken) {
      cancelToken.cancel('Same request is executing')
    }
    cancelToken = axios.CancelToken.source()
    return axiosOrcatec
      .post<{ data: INote }>(route, data, { cancelToken: cancelToken.token })
      .then(r => r?.data?.data)
      .catch(error => {
        if (axios.isCancel(error)) {
          console.error('Request canceled:', error?.message)
          const cancellationError = new Error('Request canceled')
          cancellationError.isCancellationError = true
          throw cancellationError
        }

        throw error
      })
  },
  editEntityNote: (route: string, data: INote) =>
    axiosOrcatec
      .put<{ data: INote }>(route, data)
      .then(r => r?.data?.data)
      .catch(e => console.error(e)),
  deleteEntityNote: (route: string) =>
    axiosOrcatec
      .delete(route)
      .then(r => r?.data)
      .catch(e => console.error(e)),
  uploadNoteMedia: (
    route: string,
    noteId: INote['id'],
    files: INote['attachments'],
  ) =>
    axiosOrcatec
      .post(`${route}/${noteId}/attachments`, { attachments: files })
      .then(r => r?.data)
      .catch(e => console.error(e)),

  deleteNoteMedia: (route: string, noteId: INote['id'], fileId: number) =>
    axiosOrcatec
      .delete(`${route}/${noteId}/attachments/${fileId}`)
      .then(r => r?.data)
      .catch(e => console.error(e)),
}

// /:entity-relation/:entity-id/notes/:note-id/attachments/:attachment-id
