import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import * as actions from 'store/Orcatec/actions'
import { connect } from 'react-redux'
import { DatePicker, notification, Popconfirm, Tooltip, Form, Spin } from 'antd'
import moment from 'moment-timezone'
import MainButton from '../../../components/buttons/MainButton'
import {
  deletePayments,
  getMerchantPaymentMethods,
  getPaymentMethods,
  postPayments,
  putPayments,
  getPaymentBalanceNames,
} from 'api/Payment'
import Preloader from '../../../components/Preloader'
import {
  priceToView,
  thousandSeparator,
} from '../../../../../../helpers/thousandSeparator'
import { InputAdornment } from '@material-ui/core'
import {
  BankCheckIcon,
  CreditCardIcon,
  DeleteIcon,
  DraggableIcon,
} from '../../../components/Icons/CommonIcons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ModuleStatus from '../ModuleStatus'
import { getPaymentStatus } from '../../helpers/getPaymentStatus'
import InputFieldV2 from 'containers/MainContent/Orcatec/components/UI/InputField'
import { BalanceName } from '../BalanceName/BalanceName'
import Select from 'containers/MainContent/Orcatec/components/UI/Select'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useAppSelector } from 'store/Orcatec/hooks'
import { IPaymentMethods, PaymentBalance } from 'types/Proposal'

import './BalanceModal.scss'
import { ProjectStatusGroup } from 'features/Project/types'
import { InfoBlock } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/InfoBlock'
import {
  selectProjectActiveTab,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const statusList = [
  {
    id: 1,
    name: 'No Action Needed',
  },
  {
    id: 2,
    name: 'Balance Due',
  },
  {
    id: 3,
    name: 'Balance Overdue',
  },
  {
    id: 4,
    name: 'Complete',
  },
]

const countTotals = (balances: PaymentBalance[]) => {
  let totalAmount = 0
  let dueAmount = 0
  if (balances?.length) {
    balances.forEach(item => {
      if (item.status === 4) return
      totalAmount += item.amount === '-' || !item.amount ? 0 : +item.amount
      if (item.status !== 3) {
        dueAmount += item.amount === '-' || !item.amount ? 0 : +item.amount
      }
    })
  }
  return [totalAmount.toFixed(2), dueAmount]
}

const PaymentSchedule = props => {
  const initialState = {
    entityType: 0,
    entityId: 0,
    tabId: 0,
    textcount: 0,
    textareabadge: false,
    data: {
      id: 0,
      entity_id: 0,
      entity_type: 0,
      tab_id: 0,
      info: '',
      remaining_balance: 0,
      total_balance: 0,
      payment_balances: [
        {
          id: 0,
          name_balance: '',
          amount: '',
          due_date: '',
          description: '',
          status: 1,
          position: 0,
          accept_card: false,
          can_pay_by_check: false,
          payment_method: '',
        },
      ],
    },
    deleteCheck: false,
    deleteIdx: 0,
    onLoading: false,
    onLoadingDelete: false,
    balanceToSplit: 0,
    totalAmount: 0,
    dueAmount: 0,
    errorBalance: false,
    transactionsToDelete: [],
    isEdited: false,
  }

  const [state, setState] = useState(initialState)
  const [fieldErrors, setFieldErrors] = useState([
    {
      name_balance: false,
      amount: false,
      due_date: false,
    },
  ])
  const [paymentMethods, setPaymentsMethods] = useState<string[]>([])
  const [balanceNames, setBalanceNames] = useState([])
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState({
    bank_check: false,
    credit_card: false,
  })
  const [loading, setLoading] = useState(false)

  const defaultPaymentDueDate =
    useAppSelector(selectProjectSettings)?.default_payment_due_date || 0

  const { payment_status: paymentStatus } = useAppSelector(
    selectProjectActiveTab,
  )
  const merchantID = useAppSelector(
    state => state.orcatec.company?.merchant?.id,
  )

  useEffect(() => {
    const fetchMerchantPaymentMethods = async () => {
      setLoading(true)
      const data = await getMerchantPaymentMethods(merchantID)
      setLoading(false)

      setAvailablePaymentMethods(data?.payment_methods)
    }

    if (merchantID) fetchMerchantPaymentMethods()
  }, [])

  useEffect(() => {
    const getBalanceNames = async () => {
      const data = await getPaymentBalanceNames()

      setBalanceNames(data)
    }

    getBalanceNames()
  }, [])

  useEffect(() => {
    if (props.entityId && props.entityType) {
      props.fetchPaymentsList(props.entityId, props.entityType, props.tabId)
    }
  }, [props.entityId, props.entityType, props.tabId])

  useEffect(() => {
    return () => {
      if (props.entityId && props.entityType) {
        props.fetchPaymentsList(props.entityId, props.entityType, props.tabId)
      }
    }
  }, [])

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const data: IPaymentMethods[] = await getPaymentMethods()

      setPaymentsMethods(data?.map(method => method.method_name))
    }

    if (props.isOpen) fetchPaymentMethods()
  }, [props.isOpen])

  useEffect(() => {
    if (props.isOpen && !loading) {
      let payments = props?.payments ? { ...props.payments } : {}

      if (!payments?.payment_balances?.length) {
        payments = {
          ...payments,
          payment_balances: [
            {
              id: 0,
              name_balance: 'Balance',
              amount: props.totalToPay ?? 0,
              due_date: moment().format('YYYY-MM-DD'),
              description: '',
              status: 1,
              position: 0,
              accept_card:
                !!props.hasActiveMerchant &&
                availablePaymentMethods?.credit_card,
              can_pay_by_check:
                !!props.hasActiveMerchant &&
                availablePaymentMethods?.bank_check,
              payment_method: '',
            },
          ],
        }
      }

      const errors = payments.payment_balances.map(() => ({
        name_balance: false,
        amount: false,
        due_date: false,
        description: false,
      }))
      setFieldErrors(errors)
      setState(prev => {
        if (payments) prev.data = { ...prev.data, ...payments }

        if (prev.data.info) {
          prev.textcount = prev.data.info?.length
          prev.textareabadge = true
        }
        if (props.tabId) {
          prev.tabId = props.tabId
          prev.data.tab_id = props.tabId
        }
        if (props.entityId) {
          prev.entityId = props.entityId
          prev.data.entity_id = props.entityId
        }
        if (props.entityType) {
          prev.entityType = props.entityType
          prev.data.entity_type = props.entityType
        }
        if (props.totalToPay) {
          prev.balanceToSplit = props.totalToPay
          prev.data.total_balance = props.totalToPay
        }
        const [totalAmount, dueAmount] = countTotals(prev.data.payment_balances)
        prev.totalAmount = totalAmount
        prev.data.remaining_balance = dueAmount //thousandSeparator(prev.balanceToSplit - prev.totalAmount).replace(/,/g,'')
        prev.dueAmount = dueAmount
        prev.deleteCheck = false
        prev.deleteIdx = 0
        return prev
      })
    } else {
      setFieldErrors([])
      setState({ ...initialState })
    }
  }, [props.isOpen, props.payments, availablePaymentMethods])

  useEffect(() => {
    setState(prev => ({
      ...prev,
      data: {
        ...state.data,
        payment_balances: state.data.payment_balances.map((balance, idx) => ({
          ...balance,
          position: idx,
        })),
      },
    }))
  }, [state.data?.payment_balances?.length])

  const addPayment = () => {
    setFieldErrors(prev => {
      prev.push({
        name_balance: false,
        amount: false,
        due_date: false,
        // description: false,
      })
      return prev
    })
    setState(data => {
      const prev = JSON.parse(JSON.stringify(data))
      if (!Array.isArray(prev.data.payment_balances))
        prev.data.payment_balances = []

      const [sumOfBalances] = countTotals(prev.data.payment_balances)

      prev.data.payment_balances.push({
        id: 0,
        name_balance: 'Balance',
        amount: +(+state.balanceToSplit - +sumOfBalances).toFixed(2),
        due_date: moment()
          .add(defaultPaymentDueDate, 'days')
          .format('YYYY-MM-DD'),
        description: '',
        status: 1,
        accept_card:
          !!props.hasActiveMerchant && availablePaymentMethods.credit_card,
        can_pay_by_check:
          !!props.hasActiveMerchant && availablePaymentMethods.bank_check,
        position: prev.data.payment_balances?.length,
        payment_method: '',
      })

      const [totalAmount, dueAmount] = countTotals(prev.data.payment_balances)

      prev.totalAmount = totalAmount
      prev.data.remaining_balance = dueAmount //thousandSeparator(prev.balanceToSplit - prev.totalAmount).replace(/,/g,'')
      prev.dueAmount = dueAmount
      prev.isEdited = true
      return prev
    })
  }

  const removePayment = (e: React.MouseEvent<HTMLSpanElement>, i: number) => {
    e.preventDefault()
    setState(data => {
      const prev = JSON.parse(JSON.stringify(data))
      prev.deleteCheck = !!prev.data.payment_balances[i]
      prev.deleteIdx = i
      prev.isEdited = true
      return prev
    })
  }

  const changeField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number,
    key: string,
    isOnBlur = false,
  ) => {
    const errors = [...fieldErrors]
    const newState = JSON.parse(JSON.stringify(state))
    let value = ''
    if (key === 'isPaid') {
      value = !newState.data.payment_balances[i][key]
    } else if (key === 'status') {
      value = e
      if (+value === 3)
        newState.data.payment_balances[i].due_date = moment().format(
          'YYYY-MM-DD',
        )
    } else if (key === 'due_date') {
      value = moment(e).format('YYYY-MM-DD')
      if (moment(e).isBefore(moment(), 'day')) {
        if (+newState.data.payment_balances[i].status === 1) {
          newState.data.payment_balances[i].status = 2
        }
      } else {
        if (+newState.data.payment_balances[i].status === 2) {
          newState.data.payment_balances[i].status = 1
          changeField(1, i, 'status')
        }
      }
    } else if (key === 'description') {
      if (e?.length >= 256) value = e.substring(0, 256)
      else value = e
    } else if (key === 'name_balance') {
      if (e?.length >= 256) value = e.substring(0, 256)
      else value = e
    } else if (key === 'amount') {
      value = e ? e : ''
      value = value.replace(/,/g, '')
      value = value.replace(/^-+/, '-')
      const checkValue = value.replace(/^-/, '')
      const isNumber = !isNaN(checkValue)
      if (isNumber) {
        const parts = value.split('.')
        if (parts?.length > 1 && parts[1]?.length > 2) {
          parts[1].replace(/.$/, '')
          value = parts.join('.')
        }
      } else {
        value = value.replace(/[^0-9.]/g, '')
      }
    } else if (key === 'accept_card' || key === 'can_pay_by_check') {
      value = !newState.data.payment_balances[i][key]
    } else {
      value = e
    }
    if (newState.data.payment_balances[i][key] !== value) {
      errors[i][key] = key === 'amount' ? value === '' : !value
      newState.data.payment_balances[i][key] = value
      newState.isEdited = true
      if (isOnBlur) {
        const [totalAmount, dueAmount] = countTotals(
          newState.data.payment_balances,
        )
        newState.totalAmount = totalAmount
        newState.data.remaining_balance = dueAmount
        newState.dueAmount = dueAmount
      }
    }
    if (key === 'status' /* || key === 'amount' */ || key === 'due_date') {
      const [totalAmount, dueAmount] = countTotals(
        newState.data.payment_balances,
      )
      newState.totalAmount = totalAmount
      newState.data.remaining_balance = dueAmount
      newState.dueAmount = dueAmount
      newState.isEdited = true
    }
    setFieldErrors(errors)
    setState(newState)
  }

  const handlePressEnter = (e, item, i) => {
    if (e.keyCode !== 13) return
    onBlurAmount(item, i)
  }

  const onBlurAmount = (item: PaymentBalance, i: number) => {
    const [totalAmount, dueAmount] = countTotals(state.data.payment_balances)
    const remainingBalanceToSplit = +state.balanceToSplit - totalAmount

    setState({
      ...state,
      totalAmount,
      dueAmount,
      isEdited: true,
      data: {
        ...state.data,
        remaining_balance: dueAmount,
      },
    })

    if (!item.amount || item.amount === '-') changeField('0', i, 'amount')
    if (remainingBalanceToSplit < 0) {
      const correctBalance = +(
        +state.balanceToSplit -
        totalAmount +
        +item.amount
      ).toFixed(2)
      changeField(correctBalance.toString(), i, 'amount', true)
    }
  }

  const textareachange = e => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      textareabadge: value?.length > 0,
      textcount: value?.length,
      data: { ...prev.data, [name]: value },
      isEdited: true,
    }))
  }

  const onSave = () => {
    let isValid = true
    const errors = [...fieldErrors]
    state.data.payment_balances.forEach((item, idx) => {
      const name_balance = !item.name_balance
      const amount = item.amount === ''
      const due_date = !item.due_date
      if (name_balance || amount || due_date /*||  description */) {
        isValid = false
        errors[idx] = {
          name_balance,
          amount,
          due_date,
          // description,
        }
      }
    })
    if (isValid) {
      if (state.transactionsToDelete?.length) {
        setState(prev => ({ ...prev, onLoadingDelete: true }))
        deletePayments(state.transactionsToDelete)
          .then(() => {
            props.fetchPaymentsList(
              props.entityId,
              props.entityType,
              props.tabId,
            )
          })
          .finally(() => {
            setState(prev => ({ ...prev, onLoadingDelete: false }))
          })
      }
      // if (state.balanceToSplit - state.totalAmount === 0) {
      setState(prev => ({ ...prev, onLoading: true, errorBalance: false }))
      const request = state.data.id
        ? putPayments(state.data.id, state.data)
        : postPayments(state.data)
      request
        .then(() => {
          props.changePaymentStatus(
            getPaymentStatus(state.data.payment_balances, props.totalToPay),
          )
        })
        .then(() => props.openCloseModalPayment())
        .then(() =>
          props.fetchPaymentsList(
            props.entityId,
            props.entityType,
            props.tabId,
          ),
        )
        .catch(e => notification.error({ message: e?.message }))
        .finally(() => setState(prev => ({ ...prev, onLoading: false })))
    } else {
      setFieldErrors(errors)
    }
  }

  const onDelete = () => {
    const idToDelete = state.data.payment_balances[state.deleteIdx].id

    setState(data => {
      const prev = JSON.parse(JSON.stringify(data))
      prev.data.payment_balances.splice(state.deleteIdx, 1)
      const [totalAmount, dueAmount] = countTotals(prev.data.payment_balances)
      prev.totalAmount = totalAmount
      prev.data.remaining_balance = dueAmount
      prev.dueAmount = dueAmount
      if (idToDelete !== 0) {
        prev.transactionsToDelete.push(idToDelete)
      }
      prev.deleteCheck = false
      prev.deleteIdx = null
      prev.isEdited = true
      return prev
    })
    // }
  }

  const EmptyIcon = () => ''

  const onDragEnd = ({ destination, source, reason }) => {
    if (!destination || reason === 'CANCEL') {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const transactions = Object.assign([], state.data.payment_balances)
    const droppedTransaction = state.data.payment_balances[source.index]

    transactions.splice(source.index, 1)
    transactions.splice(destination.index, 0, droppedTransaction)

    const transactionsWithPostitons = transactions.map((t, i) => ({
      ...t,
      position: i,
    }))

    setState({
      ...state,
      data: {
        ...state.data,
        payment_balances: transactionsWithPostitons,
      },
      isEdited: true,
    })
  }

  const descriptionWithCardInfo = item =>
    item.transaction_id
      ? `${item.description}. Paid by card #*******${item.card}. Transaction ID:${item.transaction_id}. Status: ${item.transaction_status}`
      : item.description

  const deleteZeroHandler = (value, i, key) => {
    const splittedNum = value.toString().split('.') || []
    if (splittedNum[1] === '0') changeField(splittedNum[0], i, key)
    else return
  }

  return (
    <Modal
      visible={props.isOpen}
      onOk={onSave}
      onCancel={() => props.openCloseModalPayment()}
      aria-labelledby='modal-payment-label'
      centered
      wrapClassName='modal-proposal-payment'
      showConfirmationOnExit={state.isEdited}
      footer={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: '10px',
          }}
        >
          <MainButton
            onClick={props.openCloseModalPayment}
            type='cancel'
            title='Cancel'
          />
          <MainButton
            onClick={onSave}
            title='Save'
            disabled={state.onLoading}
          />
        </div>
      }
    >
      <Spin spinning={loading}>
        <div style={{ paddingTop: 0 }} className='mod-paywrap'>
          <Row
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <h4 style={{ marginTop: '15px' }}>Payment Schedule</h4>
          </Row>
          <Row>
            <ModuleStatus
              statusList={statusList}
              currentStatus={paymentStatus}
              payment
            />
          </Row>

          {[
            ProjectStatusGroup.Completed /*  ProjectStatus.Canceled */,
          ].includes(props.proposalStatus) && (
            <InfoBlock
              type='info'
              style={{
                marginBottom: 20,
                fontSize: '0.85rem',
              }}
            >
              You can&apos;t apply any changes to the payments if the project is
              in <span style={{ fontWeight: 500 }}>&quot;Completed&quot;</span>{' '}
              status
            </InfoBlock>
          )}

          <Row>
            <Col>
              <p className='total-to-pay'>
                Total:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {priceToView(props.totalToPay)}
                </span>
              </p>
            </Col>
          </Row>
          <div className='modal-payment-table'>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='transactions'>
                {provided => (
                  <div
                    className='tbody'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {state.data.payment_balances &&
                      [...state.data.payment_balances]
                        .sort((a, b) => a.position - b.position)
                        .map((item, i) => {
                          // console.log(item, 'item')
                          const errorMessage = 'This field is required'

                          const amountError =
                            fieldErrors[i] && fieldErrors[i].amount
                              ? errorMessage
                              : ''
                          const dueDateError =
                            fieldErrors[i] && fieldErrors[i].due_date
                              ? errorMessage
                              : ''

                          const hasTransactions =
                            item.payment_transactions &&
                            item.payment_transactions?.length > 0
                          const allRejected =
                            item.payment_transactions &&
                            item.payment_transactions.every(tr =>
                              ['Declined', 'Rejected', 'Voided'].some(
                                d => d === tr.transaction_status,
                              ),
                            )
                          const hasRejected =
                            item.payment_transactions &&
                            item.payment_transactions.some(tr =>
                              ['Declined', 'Rejected', 'Voided'].some(
                                d => d === tr.transaction_status,
                              ),
                            )
                          const hasAccepted =
                            item.payment_transactions &&
                            item.payment_transactions.some(
                              tr =>
                                tr.transaction_status === 'Accepted' ||
                                tr.transaction_status === 'Queued for Capture',
                            )

                          return (
                            <Draggable
                              // key={`bal_${new Date().getTime()}`}
                              key={i}
                              index={i}
                              draggableId={i + ''}
                            >
                              {provided => (
                                <div
                                  className={`rowWrapper${
                                    hasTransactions ? ' filled' : ''
                                  }`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div className='payment-balance'>
                                    <div className='payment-balance__content'>
                                      <div className='content-row'>
                                        {![
                                          ProjectStatusGroup.Completed,
                                          // ProjectStatus.Canceled,
                                        ].includes(props.proposalStatus) && (
                                          <div
                                            {...provided.dragHandleProps}
                                            className='dragIcon'
                                          >
                                            <DraggableIcon />
                                          </div>
                                        )}
                                        <BalanceName
                                          balanceNames={balanceNames}
                                          setBalanceNames={setBalanceNames}
                                          value={item.name_balance}
                                          onChange={value =>
                                            changeField(
                                              value,
                                              i,
                                              'name_balance',
                                            )
                                          }
                                          disabled={[
                                            ProjectStatusGroup.Completed,
                                            // ProjectStatus.Canceled,
                                          ].includes(props.proposalStatus)}
                                        />

                                        <Form.Item
                                          validateStatus={
                                            dueDateError ? 'error' : 'success'
                                          }
                                          help={dueDateError}
                                        >
                                          <p style={{ color: '#0000008a' }}>
                                            Due Date
                                          </p>
                                          <DatePicker
                                            allowClear={false}
                                            value={
                                              item.due_date
                                                ? moment(item.due_date)
                                                : null
                                            }
                                            // disabled={+item.status === 3 || +item.status === 4 || +item.status === 5}
                                            format='MM/DD/YYYY'
                                            suffixIcon={EmptyIcon} //hide calendar icon
                                            placeholderText='--/--/----'
                                            onChange={e =>
                                              changeField(e, i, 'due_date')
                                            }
                                            getCalendarContainer={() =>
                                              document.getElementsByClassName(
                                                'mod-paywrap',
                                              )[0]
                                            }
                                            getPopupContainer={triggerNode =>
                                              triggerNode.parentNode
                                            }
                                            disabled={[
                                              ProjectStatusGroup.Completed,
                                              // ProjectStatus.Canceled,
                                            ].includes(props.proposalStatus)}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            amountError ? 'error' : 'success'
                                          }
                                          help={amountError}
                                        >
                                          <InputFieldV2
                                            label='Amount'
                                            name='amount'
                                            index={i}
                                            value={thousandSeparator(
                                              item.amount,
                                            )}
                                            onKeyUp={e =>
                                              handlePressEnter(e, item, i)
                                            }
                                            onChange={e =>
                                              changeField(
                                                e.currentTarget.value,
                                                i,
                                                'amount',
                                              )
                                            }
                                            onBlur={e => {
                                              onBlurAmount(item, i)
                                              deleteZeroHandler(
                                                e.currentTarget.value,
                                                i,
                                                'amount',
                                              )
                                            }}
                                            disabled={
                                              +item.status === 3 ||
                                              +item.status === 4 ||
                                              +item.status === 5 ||
                                              [
                                                ProjectStatusGroup.Completed,
                                                // ProjectStatus.Canceled,
                                              ].includes(
                                                props.proposalStatus,
                                              ) ||
                                              hasRejected
                                            }
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position='start'>
                                                  $
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Form.Item>

                                        <Select
                                          label='Status'
                                          className='status'
                                          name='interval'
                                          value={item.status}
                                          onChange={e => {
                                            if (props.proposalStatus === 1)
                                              return openNotificationWithIcon(
                                                'error',
                                                {
                                                  description:
                                                    'Payment status cannot be changed for a project in the Estimate status',
                                                },
                                              )
                                            changeField(
                                              e.target.value,
                                              i,
                                              'status',
                                            )
                                          }}
                                          options={[
                                            {
                                              id: 1,
                                              name: 'Due',
                                              disabled: moment(
                                                item.due_date,
                                              ).isBefore(
                                                moment(new Date()).subtract(
                                                  1,
                                                  'days',
                                                ),
                                              ),
                                            },
                                            {
                                              id: 2,
                                              name: 'Overdue',
                                              disabled: !moment(
                                                item.due_date,
                                              ).isBefore(
                                                moment(new Date()).subtract(
                                                  1,
                                                  'days',
                                                ),
                                              ),
                                            },
                                            {
                                              id: 3,
                                              name: 'Paid',
                                              disabled:
                                                item.payment_transactions
                                                  ?.length,
                                            },
                                            {
                                              id: 4,
                                              name: 'Cancelled',
                                              disabled:
                                                !allRejected ||
                                                !hasTransactions,
                                            },
                                            {
                                              id: 5,
                                              name: 'Pending',
                                              disabled: true,
                                            },
                                          ]}
                                          noValue={undefined}
                                          disabled={
                                            hasAccepted ||
                                            item.status === 5 ||
                                            [
                                              ProjectStatusGroup.Completed,
                                              // ProjectStatus.Canceled,
                                            ].includes(props.proposalStatus)
                                          }
                                        />
                                      </div>

                                      <div className='content-row__second'>
                                        <InputFieldV2
                                          fullWidth
                                          name='description'
                                          index={i}
                                          error={
                                            item.description?.length >= 256 && {
                                              description:
                                                'Max length 255 symbols',
                                            }
                                          }
                                          value={descriptionWithCardInfo(item)}
                                          onChange={e =>
                                            changeField(
                                              e.currentTarget.value,
                                              i,
                                              'description',
                                            )
                                          }
                                          label='Payment Description'
                                        />

                                        <Select
                                          label='Payment Method'
                                          className='status'
                                          value={item.payment_method}
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              i,
                                              'payment_method',
                                            )
                                          }
                                          options={paymentMethods}
                                          disabled={
                                            item.status !== 3 ||
                                            [
                                              ProjectStatusGroup.Completed,
                                              // ProjectStatus.Canceled,
                                            ].includes(props.proposalStatus)
                                          }
                                          noValue
                                          textValue
                                        />
                                      </div>
                                    </div>
                                    <div className='payment-balance__actions'>
                                      <>
                                        {/* {props.hasActiveMerchant && availablePaymentMethods?.credit_card && ( */}
                                        <Tooltip
                                          title={`${
                                            !props.hasActiveMerchant
                                              ? 'To procces creadit card payments please enable it with CloverConnect'
                                              : item.accept_card
                                              ? 'Disable Payment by CC'
                                              : 'Enable Payment by CC'
                                          }`}
                                          className={`card-icon ${
                                            item.transaction_id ||
                                            +item.amount < 0 ||
                                            +item.status === 3 ||
                                            +item.status === 4 ||
                                            +item.status === 5 ||
                                            [
                                              ProjectStatusGroup.Completed,
                                              // ProjectStatus.Canceled,
                                            ].includes(props.proposalStatus)
                                              ? 'disabled'
                                              : ''
                                          }`}
                                        >
                                          <Popconfirm
                                            placement='left'
                                            title={
                                              'Are you sure? Your client will not be able to pay this balance with Credit Card'
                                            }
                                            disabled={!item?.accept_card}
                                            onConfirm={() => {
                                              if (
                                                item.transaction_id ||
                                                +item.amount < 0 ||
                                                +item.status === 3 ||
                                                +item.status === 4 ||
                                                +item.status === 5
                                              )
                                                return

                                              changeField(
                                                null,
                                                i,
                                                'accept_card',
                                              )
                                            }}
                                            okText='Yes'
                                            cancelText='No'
                                          >
                                            <span
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                if (
                                                  item.transaction_id ||
                                                  +item.amount < 0 ||
                                                  +item.status === 3 ||
                                                  +item.status === 4 ||
                                                  +item.status === 5 ||
                                                  !availablePaymentMethods?.credit_card ||
                                                  item?.accept_card
                                                )
                                                  return

                                                changeField(
                                                  null,
                                                  i,
                                                  'accept_card',
                                                )
                                              }}
                                            >
                                              <CreditCardIcon
                                                fill={
                                                  props.hasActiveMerchant
                                                    ? item?.accept_card &&
                                                      +item?.amount >= 0
                                                      ? '#4c53ef'
                                                      : '#f5222d'
                                                    : '#b6b6b6'
                                                }
                                              />
                                            </span>
                                          </Popconfirm>
                                        </Tooltip>
                                        {/* )} */}

                                        {/* {props.hasActiveMerchant && ( */}
                                        <Tooltip
                                          title={`${
                                            !availablePaymentMethods?.bank_check ||
                                            !props.hasActiveMerchant
                                              ? 'To procces check payments please enable it with CloverConnect'
                                              : item.can_pay_by_check
                                              ? 'Disable Payment by ACH'
                                              : 'Enable Payment by ACH'
                                          }`}
                                          className={`card-icon ${
                                            [
                                              ProjectStatusGroup.Completed,
                                              // ProjectStatus.Canceled,
                                            ].includes(props.proposalStatus) ||
                                            item.transaction_id ||
                                            +item.amount < 0 ||
                                            +item.status === 3 ||
                                            +item.status === 4 ||
                                            +item.status === 5
                                              ? 'disabled'
                                              : ''
                                          }`}
                                        >
                                          <Popconfirm
                                            placement='left'
                                            title={
                                              'Are you sure? Your client will not be able to pay this balance with bank check'
                                            }
                                            disabled={!item.can_pay_by_check}
                                            onConfirm={() => {
                                              if (
                                                item.transaction_id ||
                                                +item.amount < 0 ||
                                                +item.status === 3 ||
                                                +item.status === 4 ||
                                                +item.status === 5 ||
                                                !availablePaymentMethods?.bank_check
                                              )
                                                return

                                              changeField(
                                                null,
                                                i,
                                                'can_pay_by_check',
                                              )
                                            }}
                                            okText='Yes'
                                            cancelText='No'
                                          >
                                            <span
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                if (
                                                  item.transaction_id ||
                                                  +item.amount < 0 ||
                                                  +item.status === 3 ||
                                                  +item.status === 4 ||
                                                  +item.status === 5 ||
                                                  !availablePaymentMethods?.bank_check ||
                                                  item?.can_pay_by_check
                                                )
                                                  return

                                                changeField(
                                                  null,
                                                  i,
                                                  'can_pay_by_check',
                                                )
                                              }}
                                            >
                                              <BankCheckIcon
                                                fill={
                                                  availablePaymentMethods.bank_check
                                                    ? item?.can_pay_by_check &&
                                                      +item?.amount >= 0
                                                      ? '#4c53ef'
                                                      : '#f5222d'
                                                    : '#b6b6b6'
                                                }
                                              />
                                            </span>
                                          </Popconfirm>
                                        </Tooltip>
                                        {/* )} */}
                                      </>

                                      {state.onLoadingDelete &&
                                      state.deleteId === item.id ? (
                                        <Preloader />
                                      ) : (
                                        <Popconfirm
                                          title='Are you sure to delete this balance?'
                                          onConfirm={onDelete}
                                          onCancel={() =>
                                            setState({
                                              ...state,
                                              deleteCheck: false,
                                              deleteIdx: null,
                                            })
                                          }
                                          okText='Yes'
                                          cancelText='No'
                                          disabled={
                                            item.transaction_id ||
                                            +item.status === 3 ||
                                            +item.status === 4 ||
                                            +item.status === 5 ||
                                            hasTransactions
                                          }
                                        >
                                          <span
                                            className={`delete-icon ${
                                              item.transaction_id ||
                                              +item.status === 3 ||
                                              +item.status === 4 ||
                                              +item.status === 5 ||
                                              [
                                                ProjectStatusGroup.Completed,
                                                // ProjectStatus.Canceled,
                                              ].includes(
                                                props.proposalStatus,
                                              ) ||
                                              hasTransactions
                                                ? 'disabled'
                                                : ''
                                            }`}
                                            onClick={e => {
                                              removePayment(e, i)
                                            }}
                                          >
                                            <DeleteIcon />
                                          </span>
                                        </Popconfirm>
                                      )}
                                    </div>
                                  </div>
                                  {hasTransactions && (
                                    <div className='payment-transactions'>
                                      <div className='payment-transactions__row heading'>
                                        <p className='id'>Transaction ID</p>
                                        <p className='acc_holder'>
                                          Account Holder
                                        </p>
                                        <p className='acc_number'>Account #</p>
                                        <p className='check_number'>Check #</p>
                                        <p className='method'>Method</p>
                                        <p className='processed_by'>
                                          Processed by
                                        </p>
                                        <p className='date'>Date</p>
                                        <p className='status'>Status</p>
                                      </div>
                                      {item.payment_transactions.map(
                                        (tr, idx) => (
                                          <div
                                            className='payment-transactions__row'
                                            key={idx}
                                          >
                                            <p className='id'>
                                              {tr.transaction_id ?? ''}
                                            </p>
                                            <p className='acc_holder'>
                                              {tr.card_holder || '-'}
                                            </p>
                                            <p className='acc_number'>{`#*******${tr.card}`}</p>
                                            <p className='check_number'>
                                              {tr.check_number || '-'}
                                            </p>
                                            <p className='method'>
                                              {tr?.type === 1 ? 'Card' : 'ACH'}
                                            </p>
                                            <p className='processed_by'>
                                              {tr?.payer || '-'}
                                            </p>
                                            <p className='date'>
                                              {moment
                                                .utc(tr.created_at)
                                                .local()
                                                .format('MM/DD/YYYY, h:mm a')}
                                            </p>
                                            <p className='status'>
                                              {tr.transaction_status}
                                            </p>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Row className='balance-controls'>
            <Col
              style={{
                padding: '0px 0px 10px',
                flexBasis: '100%',
              }}
            >
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <MainButton
                  title='Add new Balance'
                  className='btn btn-primary'
                  // disabled={+state.balanceToSplit - +state.totalAmount === 0}
                  onClick={addPayment}
                  disabled={[
                    ProjectStatusGroup.Completed,
                    // ProjectStatus.Canceled,
                  ].includes(props.proposalStatus)}
                />

                {/* {+state.balanceToSplit - +state.totalAmount === 0 && (
                <Tooltip title='You have no remaining balance to split'>
                  <InfoIcon style={{ fontSize: '20px', color: '#4c53ef' }} />
                </Tooltip>
              )} */}
              </div>
            </Col>
            <Col>
              <div style={{ textAlign: 'right', marginRight: 30 }}>
                {state.balanceToSplit - state.totalAmount !== 0 && (
                  <p>
                    Remaining Balance to Split{' '}
                    <span className='balance_left red'>
                      {priceToView(+state.balanceToSplit - +state.totalAmount)}
                    </span>
                  </p>
                )}
                {!!+state.dueAmount && (
                  <p>
                    Total Balance Due{' '}
                    <span className='balance_left'>
                      {priceToView(
                        +state.dueAmount +
                          +state.balanceToSplit -
                          +state.totalAmount,
                      )}
                    </span>
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row className='payment-information'>
            <Col>
              <label htmlFor='internal_payment_information'>
                Internal payment information
              </label>
              <InputFieldV2
                fullWidth
                variant='outlined'
                placeholder='This section is not visible to the client.'
                multiline
                value={state.data.info}
                name='info'
                onChange={textareachange}
                inputProps={{
                  maxLength: 500,
                }}
                rows={5}
              />
              {state.textareabadge && (
                <span className='textcenterbadge'>
                  {state.textcount} / 500{' '}
                </span>
              )}
            </Col>
          </Row>

          {state.errorBalance && (
            <div className='right text-right' style={{ height: '21px' }}>
              <span className='error pl-4'>
                Error: Balance to split must be equal to $0
              </span>
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

const mapDispatchToProps = {
  openModal: actions.modalPayment.openModal,
  fetchPaymentsList: actions.payments.fetchPaymentsList,
}

export default connect(null, mapDispatchToProps)(PaymentSchedule)
