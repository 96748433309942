import { UploadOutlined } from '@ant-design/icons'
import { Progress, Spin, Upload, UploadProps } from 'antd'
import { deleteMedia } from 'api/Media'
import { Button } from 'components/UIKit'
import { ViewFile } from 'helpers/viewFile/viewFile'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { useEffect, useState } from 'react'
import FileUploadService from 'services/FileUploadService'
import styled from 'styled-components'
import { Media } from 'types/Media'

const fileStyle = {
  width: '100px',
  height: '100px',
  borderRadius: '4px',
  cursor: 'zoom-in',
}
const dellIconStyle = { fontSize: '1.2rem' }

interface Props extends UploadProps {
  defaultFilesList: Media[]
  usePreviewForImage: boolean
  onFilesChange: (files: Media[]) => void
}

export const ImageUpload = ({
  defaultFilesList,
  usePreviewForImage,
  onFilesChange,
  disabled,
  ...props
}: Props) => {
  const [files, setFiles] = useState(defaultFilesList || [])
  const [filesToUpload, setFilesToUpload] = useState([])

  useEffect(() => {
    onFilesChange(files)
  }, [files.length])

  const handleFileUpload = async (options: UploadRequestOption) => {
    const { file } = options

    setFilesToUpload(prev => [...prev, file])

    try {
      const res = await FileUploadService.upload(file, progress => {
        setFilesToUpload(prev =>
          prev.map(f =>
            f.uid === file.uid
              ? {
                  ...file,
                  progress:
                    progress.percentCompleted === 100
                      ? 99
                      : progress.percentCompleted,
                  loading: true,
                }
              : f,
          ),
        )
      })

      setFiles(prev => [...prev, ...res])
      setFilesToUpload(prev => prev.filter(f => f.uid !== file.uid))
    } catch (error) {
      console.error(error)
    }
  }

  const handleFileDelete = async (file: Media) => {
    try {
      const id = file.id

      const filteredFiles = files.filter(f => f.id !== id)

      setFiles(filteredFiles)

      deleteMedia(id)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Wrapper>
      {!disabled && (
        <Upload
          multiple
          listType='picture'
          customRequest={handleFileUpload}
          showUploadList={false}
          {...props}
        >
          <Button icon={<UploadOutlined />}>Upload files</Button>
        </Upload>
      )}

      <FilesList>
        {[...files, ...filesToUpload]?.map(file => (
          <Spin
            spinning={file.progress < 100 || !!file.loading}
            key={file?.id || file.uid}
          >
            <ViewFile
              file={file}
              showName={true}
              fileStyle={fileStyle}
              dellIconStyle={dellIconStyle}
              onDelete={disabled ? undefined : () => handleFileDelete(file)}
              isFullViewNeeded={true}
              isDellConfirmation={true}
              customPreviewLink={usePreviewForImage ? undefined : file.source}
              withPlaceholder
            />

            {file.progress && <Progress percent={file.progress} size='small' />}
          </Spin>
        ))}
      </FilesList>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const FilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`
