import { useEffect, useState } from 'react'
import { ProjectItem, ProjectStatusGroup } from '../../../types'
import { Tooltip, Spin, Button } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  addProjectItem,
  deleteProjectItem,
  itemsSet,
  optionGroupCreated,
  optionGroupReset,
  resetProjectItemsErrors,
  updateProjectItem,
} from 'features/Project/slices/projectItemsSlice'
import {
  selectProject,
  selectProjectItemsBySection,
  selectProjectItemsSlice,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import {
  Table,
  TableContent,
  TableHeading,
  Wrapper,
} from './ProjectItemsTable.styles'
import TableRow from './components/TableRow'
import { OptionGroup } from './components/OptionGroup/OptionGroup'
import { round } from 'helpers/Math'

import { Item } from 'features/Items/components/Item/Item'
import ItemsAPI from 'api/Items'
import { ItemAttachment } from 'features/Items/types'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import ModalCancelContract from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalCancelContract/ModalCancelContract'
import { resetSignature } from 'store/Orcatec/actions/proposal/proposalForm'
import ProjectItemsAPI from 'api/Project'
import { reorderEntities } from 'features/Project/helpers'

const initialItem = {
  attachments: [],
  barcode: '',
  category_id: 0,
  checked_option: false,
  description: '',
  global_item_id: null,
  gross_margin: null,
  group_id: 0,
  id: 0,
  is_hidden: false,
  is_material: false,
  name: '',
  net_price: 0,
  option_group_id: null,
  order_option: 0,
  position: 0,
  qty: 1,
  required_to_pay: true,
  retail_price: 0,
  section_id: 0,
  tab_id: 0,
  taxable: false,
  unit_id: null,
  unit: '',
  use_calculation: true,
}

interface Props {
  disabled: boolean
  groupId: number | null
  sectionId: number
  isNewRowtriggered: boolean
  onItemAdd: (value: boolean) => void
}

export const ProjectItemsTable = ({
  sectionId,
  groupId,
  disabled,
  isNewRowtriggered,
  onItemAdd,
}: Props) => {
  const [editingItem, setEditingItem] = useState<ProjectItem | null>(null)
  const [newRow, setNewRow] = useState(false)
  const [itemModal, setItemModal] = useState(false)
  const [itemToAprove, setItemToApprove] = useState(null)
  const [resetingSignature, setResetingSignature] = useState(false)

  const dispatch = useAppDispatch()
  const { signature, status: projectStatus, id: projectId } = useAppSelector(
    selectProject,
  )
  const { completion_progress } = useAppSelector(selectProjectSettings)

  const { status: itemsStatus, error } = useAppSelector(selectProjectItemsSlice)
  const { user_id, extraAssignedUsers } = useAppSelector(selectProject)
  const isCostVisible = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_READ_COST,
    ),
  )
  const itemsList = useAppSelector(
    selectProjectItemsBySection(sectionId, groupId),
  )

  useEffect(() => {
    if (error) {
      dispatch(resetProjectItemsErrors())
    }
  }, [itemModal, newRow])

  useEffect(() => {
    onItemAdd?.(newRow)
  }, [newRow])

  const toggleItemsModal = () => {
    setItemModal(!itemModal)
  }

  const handleEditItem = (item: ProjectItem) => {
    if (disabled && item.approved) return

    setEditingItem(item.id === editingItem?.id ? null : item)

    if (newRow) setNewRow(false)
  }

  const handleChangeItem = (e: {
    target: {
      name: string
      value: string | number | boolean | ItemAttachment[]
    }
  }) => {
    if (!editingItem) return

    const { name, value } = e.target

    if (
      [
        'name',
        'description',
        'net_price',
        'retail_price',
        'gross_margin',
        'is_material',
        'taxable',
      ].includes(name)
    ) {
      if (editingItem.gross_margin !== null) {
        if (name === 'net_price') {
          const calculatedRetailPrice =
            value + round((value * editingItem?.gross_margin || 0) / 100)

          setEditingItem(prevItem => ({
            ...prevItem,
            retail_price: calculatedRetailPrice,
          }))
        }
        if (name === 'gross_margin') {
          const calculatedRetailPrice =
            editingItem?.net_price +
            round((editingItem?.net_price * value || 0) / 100)

          setEditingItem(prevItem => ({
            ...prevItem,
            retail_price: calculatedRetailPrice,
          }))
        }
      }

      setEditingItem(prev => ({
        ...prev,
        global_item_id: null,
      }))
    }

    if (name === 'required_to_pay' && value) {
      setEditingItem(prev => ({
        ...prev,
        use_calculation: true,
      }))
    }

    if (error?.[name]) {
      dispatch(resetProjectItemsErrors([name]))
    }

    setEditingItem(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSaveItem = async (item: ProjectItem) => {
    const res = item?.id
      ? await dispatch(
          updateProjectItem({
            ...item,
            group_id: groupId,
            net_price: item.net_price || 0,
            retail_price: item.retail_price || 0,
            id: editingItem?.id || item.id,
            section_id: sectionId,
            qty: item.qty ?? 1,
            checked_option: item.checked_option ?? false,
            required_to_pay: item.required_to_pay ?? true,
            use_calculation: item.use_calculation ?? true,
            progress:
              item.installed && item.qty
                ? Math.round((item.installed / item.qty || 1) * 100)
                : 0,
          }),
        )
      : await dispatch(
          addProjectItem({
            ...item,
            section_id: sectionId,
            qty: item.qty || 1,
            group_id: groupId,
            checked_option: false,
          }),
        )

    if (res.meta.requestStatus === 'rejected') return

    setNewRow(false)
    setEditingItem(null)
    setItemModal(false)
  }

  const handleDeleteItem = async (item: ProjectItem) => {
    if (!item.id) return
    await dispatch(deleteProjectItem(item))
    setItemModal(false)
    setEditingItem(null)
  }

  const handleSearchItem = async (item: ProjectItem) => {
    if (!item) return

    if (item.global_item_id) {
      const res = await ItemsAPI.getItemById(item.global_item_id)

      setEditingItem(prev => ({
        ...prev,
        category_id: res.category_id,
      }))
    }

    toggleItemsModal()
  }

  const handleItemAdd = (optionGroupId?: number) => {
    setEditingItem({ ...initialItem, option_group_id: optionGroupId || null })

    if (optionGroupId) {
      dispatch(
        optionGroupCreated({
          ...initialItem,
          option_group_id: optionGroupId,
        }),
      )
    }
    setNewRow(!newRow)
  }

  const handleItemApprove = async (item: ProjectItem) => {
    if (signature) return setItemToApprove(item)

    handleSaveItem(item)
  }

  const handleSignatureReset = async () => {
    setResetingSignature(true)
    if (!itemToAprove) return
    await dispatch(resetSignature(projectId))
    // await dispatch(updateProposal('delete signature', 2, '', undefined, true))

    await handleSaveItem(itemToAprove)

    setResetingSignature(false)

    setItemToApprove(null)
  }

  const handleOptionsReorder = (result: DropResult, options: ProjectItem[]) => {
    if (!result.destination) return

    const reorderedItems = reorderEntities(
      options,
      result.source.index,
      result.destination.index,
      'order_option',
    )

    dispatch(itemsSet(reorderedItems))

    ProjectItemsAPI.reorderItems(reorderedItems)
  }

  return (
    <Wrapper>
      <Spin spinning={false}>
        <Table>
          {(!!itemsList.length || newRow) && (
            <TableHeading>
              <p></p>
              <p>Description</p>
              <Tooltip mouseLeaveDelay={0} title='Material'>
                <p>MATL</p>
              </Tooltip>
              <Tooltip mouseLeaveDelay={0} title='Taxable'>
                <p>TAX</p>
              </Tooltip>
              <AccessControl
                author={[user_id, ...(extraAssignedUsers || [])]}
                additionalAccess={!!isCostVisible}
                scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
              >
                <Tooltip mouseLeaveDelay={0} title='Cost'>
                  <p>Cost</p>
                </Tooltip>
              </AccessControl>
              <AccessControl
                author={[user_id, ...(extraAssignedUsers || [])]}
                additionalAccess={!!isCostVisible}
                scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
              >
                <Tooltip mouseLeaveDelay={0} title='Gross margin'>
                  <p>Margin</p>
                </Tooltip>
              </AccessControl>
              <AccessControl
                author={[user_id, ...(extraAssignedUsers || [])]}
                scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
              >
                <Tooltip mouseLeaveDelay={0} title='Retail price'>
                  <p>Price </p>
                </Tooltip>
              </AccessControl>

              <Tooltip mouseLeaveDelay={0} title='Qty'>
                <p>Qty</p>
              </Tooltip>

              <Tooltip mouseLeaveDelay={0} title='Unit'>
                <p>Unit</p>
              </Tooltip>
              <AccessControl
                author={[user_id, ...(extraAssignedUsers || [])]}
                scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
              >
                <Tooltip mouseLeaveDelay={0} title='Total'>
                  <p>Total</p>
                </Tooltip>
              </AccessControl>

              <Tooltip mouseLeaveDelay={0} title='Actions'>
                <p>Actions</p>
              </Tooltip>
            </TableHeading>
          )}

          <Droppable
            droppableId={groupId ? groupId?.toString() : 'null'}
            type='droppableItem'
          >
            {provided => (
              <TableContent
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemsList.length || newRow ? (
                  itemsList
                    ?.filter(item =>
                      item.option_group_id ? item.checked_option : item,
                    )
                    ?.map((item, itemIndex) =>
                      item?.checked_option &&
                      !!item.option_group_id &&
                      !disabled ? (
                        <Draggable
                          key={item.option_group_id}
                          draggableId={String(
                            item.option_group_id || itemIndex,
                          )}
                          index={itemIndex}
                          isDragDisabled={disabled}
                        >
                          {provided => (
                            <OptionGroup
                              key={item.id}
                              disabled={disabled || newRow || !!editingItem}
                              provided={provided}
                              optionGroupId={item.option_group_id}
                              options={itemsList.filter(
                                option =>
                                  option.option_group_id ===
                                  item.option_group_id,
                              )}
                              onAddOption={() =>
                                handleItemAdd(
                                  item?.option_group_id || undefined,
                                )
                              }
                              onOptionsReorder={handleOptionsReorder}
                            >
                              {itemsList
                                .filter(
                                  option =>
                                    option.option_group_id ===
                                    item.option_group_id,
                                )
                                ?.sort(
                                  (a, b) => a.order_option - b.order_option,
                                )
                                ?.map((option: ProjectItem) => (
                                  <TableRow
                                    isOption
                                    error={error}
                                    key={option.id}
                                    item={option}
                                    editingItem={editingItem}
                                    onEditItem={handleEditItem}
                                    onChangeItem={handleChangeItem}
                                    onSaveItem={handleSaveItem}
                                    onDeleteItem={handleDeleteItem}
                                    onCancel={() => setEditingItem(null)}
                                    onSearchItem={handleSearchItem}
                                    onCheckGrossMargin={() => {
                                      setEditingItem(prev => ({
                                        ...prev,
                                        gross_margin:
                                          editingItem?.gross_margin !== null
                                            ? null
                                            : 0,
                                      }))
                                    }}
                                    disabled={disabled || isNewRowtriggered}
                                    loading={
                                      itemsStatus === 'loading' &&
                                      option.id === editingItem?.id
                                    }
                                  />
                                ))}

                              {newRow &&
                                editingItem?.option_group_id ===
                                  item.option_group_id && (
                                  <TableRow
                                    isOption
                                    error={error}
                                    item={editingItem}
                                    editingItem={editingItem}
                                    onEditItem={handleEditItem}
                                    onChangeItem={handleChangeItem}
                                    onSaveItem={handleSaveItem}
                                    onDeleteItem={handleDeleteItem}
                                    onCancel={() => {
                                      setNewRow(false)

                                      if (
                                        !editingItem.id &&
                                        itemsList.filter(
                                          item =>
                                            item.option_group_id ===
                                            editingItem.option_group_id,
                                        ).length < 2
                                      ) {
                                        dispatch(optionGroupReset(item))
                                      }

                                      setEditingItem(null)
                                    }}
                                    onSearchItem={handleSearchItem}
                                    onCheckGrossMargin={() => {
                                      setEditingItem(prev => ({
                                        ...prev,
                                        gross_margin:
                                          editingItem?.gross_margin !== null
                                            ? null
                                            : 0,
                                      }))
                                    }}
                                    loading={itemsStatus === 'loading'}
                                  />
                                )}
                            </OptionGroup>
                          )}
                        </Draggable>
                      ) : (
                        <Draggable
                          key={item.id}
                          draggableId={String(item.id || itemIndex)}
                          index={itemIndex}
                          // isDragDisabled={disabled}
                        >
                          {provided => (
                            <TableRow
                              error={error}
                              provided={provided}
                              item={item}
                              editingItem={editingItem}
                              onEditItem={handleEditItem}
                              onChangeItem={handleChangeItem}
                              onSaveItem={handleSaveItem}
                              onDeleteItem={handleDeleteItem}
                              onCancel={() => setEditingItem(null)}
                              onSearchItem={handleSearchItem}
                              onCheckGrossMargin={() => {
                                setEditingItem(prev => ({
                                  ...prev,
                                  gross_margin:
                                    editingItem?.gross_margin !== null
                                      ? null
                                      : 0,
                                }))
                              }}
                              onAddOption={() => handleItemAdd(item?.id)}
                              onApproveItem={handleItemApprove}
                              disabled={
                                (disabled || isNewRowtriggered) && item.approved
                              }
                              loading={
                                itemsStatus === 'loading' &&
                                item.id === editingItem?.id
                              }
                              showProgress={
                                completion_progress &&
                                [
                                  ProjectStatusGroup.Contract,
                                  ProjectStatusGroup.Completed,
                                  ProjectStatusGroup.Canceled,
                                ].includes(projectStatus)
                              }
                            />
                          )}
                        </Draggable>
                      ),
                    )
                ) : (
                  <p
                    style={{
                      textAlign: 'center',
                      fontStyle: 'italic',
                      color: 'grey',
                      marginTop: 10,
                    }}
                  >
                    No items yet
                  </p>
                )}

                {newRow && !editingItem?.option_group_id && (
                  <TableRow
                    error={error}
                    item={editingItem}
                    editingItem={editingItem}
                    onEditItem={handleEditItem}
                    onChangeItem={handleChangeItem}
                    onSaveItem={handleSaveItem}
                    onDeleteItem={handleDeleteItem}
                    onCancel={() => {
                      setNewRow(false)

                      setEditingItem(null)
                    }}
                    onSearchItem={handleSearchItem}
                    onCheckGrossMargin={() => {
                      setEditingItem(prev => ({
                        ...prev,
                        gross_margin:
                          editingItem?.gross_margin !== null ? null : 0,
                      }))
                    }}
                    loading={itemsStatus === 'loading'}
                  />
                )}

                {provided.placeholder}
              </TableContent>
            )}
          </Droppable>
        </Table>

        {!disabled && (
          <Button
            style={{ marginTop: 20 }}
            onClick={() => handleItemAdd()}
            disabled={isNewRowtriggered || !!editingItem}
          >
            + Add Item
          </Button>
        )}
      </Spin>

      {itemModal && editingItem && (
        <Item
          data={editingItem}
          onClose={toggleItemsModal}
          onSave={handleSaveItem}
          onDelete={handleDeleteItem}
          title={editingItem?.id ? 'Edit Item' : 'Add Item'}
          status={itemsStatus}
          error={error}
        />
      )}

      <ModalCancelContract
        type='to edit contract'
        show={!!itemToAprove}
        onSave={handleSignatureReset}
        onHide={() => setItemToApprove(null)}
        isStatusChanging={resetingSignature}
        isSignature={signature}
      />
    </Wrapper>
  )
}
