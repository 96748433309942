export interface EventGroup {
  id: number
  event_name: string
  appointment_type_id: number | null
  preferred_technician_id: number | null
  date: string[]
  timezone: string
  time_start: string
  time_end: string
  column_template_id: (string | number)[]
  matrix_time_end: string | null
  matrix_time_start: string | null
}

export enum GroupEventsOperation {
  READ = 'can_view_group_event',
  UPDATE = 'can_edit_group_event',
  DELETE = 'can_delete_group_event',
}
