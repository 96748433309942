import { FC, useEffect, useState } from 'react'
// import { ContentContainerInner } from 'features/Project/components/EditProject/EditProject.styles'
import { Empty, Table, TablePaginationConfig, TableProps, Tooltip } from 'antd'
import { priceToView } from 'helpers/thousandSeparator'
// import styled from 'styled-components'
// import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { ArrowBtn } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { WorkOrderRowData } from 'features/Project/types'
import { WorkOrderAPI } from '../../api'
import styled from 'styled-components'
import { WOStatusInfo } from '../../types'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { setWorkOrderListTotal } from 'features/Project/slices/projectWorkOrdersSlice'

interface IProps {
  projectId: number
  handleOpenDetails: (item: WorkOrderRowData) => void
  disabled: boolean
}

export const WorkOrdersList: FC<IProps> = ({
  disabled,
  projectId,
  handleOpenDetails,
}) => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const isPriceVisible = useAppSelector(selectIsPriceVisible(me.id))

  const [list, setList] = useState([])
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    total: 10,
  })
  const [loading, setLoading] = useState(false)

  const getData = async params => {
    setLoading(true)
    const res = await WorkOrderAPI.getList({
      proposal_id: projectId,
      ...params,
    })
    setLoading(false)

    setList(res.data)
    setPagination({
      current_page: res.meta.current_page,
      per_page: res.meta.per_page,
      total: res.meta.total,
    })

    dispatch(setWorkOrderListTotal(res.meta.total))
  }

  useEffect(() => {
    if (projectId)
      getData({
        page: pagination.current_page,
        per_page: pagination.per_page,
      })
  }, [])

  const onOpenDetails = (record: WorkOrderRowData) => {
    if (disabled) return

    handleOpenDetails(record)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    getData({
      page: pagination.current,
      per_page: pagination.pageSize,
    })
  }

  const columns: TableProps<WorkOrderRowData>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'code',
      key: 'code',
      render: (code: string, record) => (
        <Tooltip title={disabled && 'Allowed to edit only in Contract status'}>
          <Link onClick={() => onOpenDetails(record)}>{code}</Link>
        </Tooltip>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Primary contact',
      dataIndex: 'primary_contact',
      key: 'primary_contact',
      render: contact => contact || '-',
    },
    {
      title: 'Primary address',
      dataIndex: 'primary_address',
      key: 'primary_address',
      render: address => address || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        <ProjectStatus color={WOStatusInfo[status].color}>
          {WOStatusInfo[status].display_name}
        </ProjectStatus>
      ),
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: value => (isPriceVisible ? priceToView(value) : '-'),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Tooltip title={disabled && 'Allowed to edit only in Contract status'}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => onOpenDetails(record)}
          >
            <ArrowBtn rotate={180} />
          </span>
        </Tooltip>
      ),
    },
  ]

  return (
    <>
      <Table
        size='small'
        dataSource={list}
        columns={columns}
        loading={loading}
        pagination={
          pagination.total > 10
            ? {
                current: pagination.current_page,
                total: pagination.total,
                pageSize: pagination.per_page,
                showSizeChanger: true,
              }
            : false
        }
        onChange={handleTableChange}
        locale={{ emptyText: <Empty description='No data yet' /> }}
      />
    </>
  )
}

const ProjectStatus = styled.span<{ color: string }>`
  background-color: ${props => props.color}33;
  color: ${props => props.color};
  padding: 4px 8px;
  border-radius: 4px;
`

const Link = styled.span`
  color: #1890ff;
  cursor: pointer;
`
