import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  createSelector,
} from '@reduxjs/toolkit'
import { AsyncThunkAPI } from 'store/types'
import { AppStateType } from 'store'
import { Error } from 'types/Error'
import { IAliases, IFolder, IAccount, IAccountsData, EmailData } from './types'
import { startAppListening } from 'store/listenerMiddleware'
import * as API from 'api/Emails'
import { IMessage } from 'features/Messaging/types'
import moment from 'moment-timezone'
import { setValueToLS } from 'hooks/useLocalStorage'
import axiosOrcatec from 'api/axiosInstance'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

import { updateDataOnItemRemoval } from './helpers'

export const NAME_IN_LS = userId => `email.${userId}`

const initialCompose: EmailData = {
  d_uid: null,
  f_path: null,
  from: '',
  to: '',
  cc: '',
  bcc: '',
  subject: '',
  priority: 3,
  type: 'html',
  content: '',
  attachments: [],
  open: false,
  isReplyTo: false,
  replyToMessage: null,
}

interface SliceState {
  status: 'idle' | 'loading' | 'error' | 'success'
  error: Error | null
  process: boolean
  accounts_loading: boolean
  box_loading: boolean
  folder_loading: boolean
  email_loading: boolean
  sending: boolean
  messages: object
  message: object
  folders: {
    [index: number]: {
      folders: IFolder[]
      aliases: IAliases
    }
  }
  compose: EmailData
  current_box: string
  current_folder: string
  current_email: string
  accounts: IAccount[]
}

const initialState: SliceState = {
  status: 'idle',
  error: null,
  process: false,
  accounts_loading: false,
  box_loading: false,
  folder_loading: false,
  email_loading: false,
  sending: false,
  folders: {},
  current_box: '',
  current_folder: '',
  current_email: '',
  accounts: [],
  messages: {},
  message: {},
  compose: initialCompose,
}

const emailsSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setActiveEmailBox: (state, { payload }: PayloadAction<string>) => {
      state.current_box = payload
      state.compose.open = false
    },
    setActiveFolderBox: (state, { payload }: PayloadAction<string>) => {
      state.current_folder = payload
      state.compose.open = false
    },
    setActiveEmailId: (state, { payload }: PayloadAction<string>) => {
      state.current_email = payload
    },
    setTokenToRequestHeader: (state, { payload }: PayloadAction<string>) => {
      axiosOrcatec.defaults.headers['mail-client-auth'] = payload
    },
    toggleCompose: state => {
      state.compose = {
        ...state.compose,
        open: !state.compose.open,
        from: state.current_box || state.accounts[0].mailbox,
      }
    },
    changeComposeData: (state, { payload }: PayloadAction<object>) => {
      state.compose = {
        ...state.compose,
        ...payload,
      }
    },
    resetComposeData: state => {
      state.compose = initialCompose
    },
    changeFolderSeenCount: (
      state,
      {
        payload,
      }: PayloadAction<{
        mailbox: string
        folder: string
        count: number
        action: 'set' | 'unset'
      }>,
    ) => {
      state.folders[payload.mailbox].folders = state.folders[
        payload.mailbox
      ].folders.map(folder =>
        folder.path === payload.folder
          ? {
              ...folder,
              unseen_count:
                payload.action === 'set'
                  ? folder.unseen_count - payload.count
                  : folder.unseen_count + payload.count,
            }
          : folder,
      )
    },
  },
  extraReducers: builder => {
    builder.addCase(getFoldersThunk.pending, state => {
      state.status = 'loading'
      state.process = true
      state.box_loading = true
    })
    builder.addCase(getFoldersThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false
      state.box_loading = false
      state.folders = {
        ...state.folders,
        [state.current_box]: payload,
      }
    })
    builder.addCase(getFoldersThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.box_loading = false
      state.error = payload
    })

    builder.addCase(getAccountsThunk.pending, state => {
      state.status = 'loading'
      state.process = true
      state.accounts_loading = true
    })
    builder.addCase(getAccountsThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false
      state.accounts_loading = false
      state.accounts = payload.data
    })
    builder.addCase(getAccountsThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.accounts_loading = false
      state.error = payload
    })

    builder.addCase(loginToMailBoxThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })
    builder.addCase(loginToMailBoxThunk.fulfilled, state => {
      state.status = 'success'
      state.process = false
    })
    builder.addCase(loginToMailBoxThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(createFolderThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })
    builder.addCase(createFolderThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false
      state.folders = {
        ...state.folders,
        [state.current_box]: payload,
      }
    })
    builder.addCase(createFolderThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(updateFolderThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })
    builder.addCase(updateFolderThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false

      state.folders = {
        ...state.folders,
        [payload.mailbox]: {
          ...state.folders[payload.mailbox],
          folders: state.folders[payload.mailbox].folders.map(item =>
            item.path === payload.from
              ? { ...item, path: payload.to, display_name: payload.to }
              : item,
          ),
        },
      }
    })
    builder.addCase(updateFolderThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(deleteFolderThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })
    builder.addCase(deleteFolderThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false

      state.folders = {
        ...state.folders,
        [payload.mailbox]: {
          ...state.folders[payload.mailbox],
          folders: state.folders[payload.mailbox].folders.filter(
            item => item.path !== payload.f_path,
          ),
        },
      }
    })
    builder.addCase(deleteFolderThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(getEmailsListByFolderThunk.pending, state => {
      state.status = 'loading'
      state.process = true
      state.folder_loading = true
    })

    builder.addCase(
      getEmailsListByFolderThunk.fulfilled,
      (state, { payload }) => {
        state.status = 'success'
        state.process = false
        state.folder_loading = false
        state.messages = {
          ...state.messages,
          [state.current_box]: {
            ...(state?.messages?.[state?.current_box] || {}),
            [state.current_folder]: {
              ...payload,
              updated_at: moment().toLocaleString(),
            },
          },
        }
      },
    )

    builder.addCase(
      getEmailsListByFolderThunk.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.process = false
        state.folder_loading = false
        state.error = payload
      },
    )

    builder.addCase(getEmailDetailsThunk.pending, state => {
      state.status = 'loading'
      state.process = true
      state.email_loading = true
    })

    builder.addCase(getEmailDetailsThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false
      state.email_loading = false
      state.message = {
        ...state.message,
        [state.current_box]: {
          ...(state?.message?.[state?.current_box] || {}),
          [state.current_folder]: {
            ...(state?.message?.[state?.current_box]?.[state.current_folder] ||
              {}),
            [+payload.id]: payload,
          },
        },
      }
    })

    builder.addCase(getEmailDetailsThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.email_loading = false
      state.error = payload
    })

    builder.addCase(deleteEmailThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })

    builder.addCase(deleteEmailThunk.fulfilled, (state, { payload }) => {
      const emailId = payload.m_uid?.[0]
      state.status = 'success'
      state.process = false
      state.messages[state.current_box][
        state.current_folder
      ].items = state.messages[state.current_box][
        state.current_folder
      ].items.filter(item => !payload.m_uid.includes(+item.id))
      delete state.messages[state.current_box][state.current_folder][emailId]
    })

    builder.addCase(deleteEmailThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(moveToThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })

    builder.addCase(moveToThunk.fulfilled, (state, { payload }) => {
      const emailId = payload.m_uid?.[0]
      state.status = 'success'
      state.process = false
      state.messages[state.current_box][
        state.current_folder
      ].items = state.messages[state.current_box][
        state.current_folder
      ].items.filter(item => !payload.m_uid.includes(+item.id))

      state.messages[state.current_box][state.current_folder] = {
        ...state.messages[state.current_box][state.current_folder],
        ...updateDataOnItemRemoval(
          {
            total:
              state.messages[state.current_box][state.current_folder].total,
            perPage:
              state.messages[state.current_box][state.current_folder].perPage,
            page: state.messages[state.current_box][state.current_folder].page,
          },
          payload?.m_uid?.length,
        ),
      }

      delete state.messages[state.current_box][state.current_folder][emailId]
      openNotificationWithIcon('success', {
        message: `Email moved sucessfully to ${payload.f_mv_path}`,
      })
    })

    builder.addCase(moveToThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(markAsFavoriteThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })

    builder.addCase(markAsFavoriteThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false
      state.messages[state.current_box][
        state.current_folder
      ].items = state.messages[state.current_box][
        state.current_folder
      ].items.map(item =>
        payload.m_uid === item.id
          ? { ...item, flags: { ...item.flags, flagged: payload?.flagged } }
          : item,
      )
    })

    builder.addCase(markAsFavoriteThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(changeFlagThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })

    builder.addCase(changeFlagThunk.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.process = false

      state.messages[state.current_box][
        state.current_folder
      ].items = state.messages[state.current_box][
        state.current_folder
      ].items.map(item =>
        payload.m_uid.includes(item.id)
          ? {
              ...item,
              flags: {
                ...item.flags,
                [payload.flag]: payload.action === 'set' ? 1 : 0,
              },
            }
          : item,
      )
    })

    builder.addCase(changeFlagThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })

    builder.addCase(sendEmailThunk.pending, state => {
      state.status = 'loading'
      state.process = true
      state.sending = true
    })

    builder.addCase(sendEmailThunk.fulfilled, state => {
      state.status = 'success'
      state.process = false
      state.sending = false
      state.compose = initialCompose
      openNotificationWithIcon('success', { message: 'The message was sent' })
    })

    builder.addCase(sendEmailThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.sending = false
      state.error = payload
    })

    builder.addCase(saveDraftEmailThunk.pending, state => {
      state.status = 'loading'
      state.process = true
    })

    builder.addCase(saveDraftEmailThunk.fulfilled, state => {
      state.status = 'success'
      state.process = false
      state.compose = initialCompose
      openNotificationWithIcon('success', { message: 'The message was sent.' })
    })

    builder.addCase(saveDraftEmailThunk.rejected, (state, { payload }) => {
      state.status = 'error'
      state.process = false
      state.error = payload
    })
  },
})

export default emailsSlice.reducer
export const {
  setActiveEmailBox,
  setActiveFolderBox,
  setActiveEmailId,
  toggleCompose,
  changeComposeData,
  setTokenToRequestHeader,
  changeFolderSeenCount,
  resetComposeData,
} = emailsSlice.actions

export const selectFoldersSlice = (state: AppStateType) =>
  state.orcatec.emailsSlice

const selectFolders = (state: AppStateType) => state.orcatec.emailsSlice.folders

const selectActiveMailBox = (state: AppStateType) =>
  state.orcatec.emailsSlice.current_box

const selectActiveFolderBox = (state: AppStateType) =>
  state.orcatec.emailsSlice.current_folder
const selectActiveEmailId = (state: AppStateType) =>
  state.orcatec.emailsSlice.current_email

const selectMessages = (state: AppStateType) =>
  state.orcatec.emailsSlice.messages

const selectMessage = (state: AppStateType) => state.orcatec.emailsSlice.message

const selectCompose = (state: AppStateType) => state.orcatec.emailsSlice.compose

const selectEmailsListLoading = (state: AppStateType) =>
  state.orcatec.emailsSlice.folder_loading

const selectFolderListLoading = (state: AppStateType) =>
  state.orcatec.emailsSlice.box_loading

const selectEmailLoading = (state: AppStateType) =>
  state.orcatec.emailsSlice.email_loading

const selectSendLoading = (state: AppStateType) =>
  state.orcatec.emailsSlice.sending

const selectAccountsFetching = (state: AppStateType) =>
  state.orcatec.emailsSlice.accounts_loading

export const selecAccounts = (state: AppStateType) =>
  state.orcatec.emailsSlice.accounts

export const selectEmailContent = (state: AppStateType) =>
  state.orcatec.emailsSlice.compose.content

export const selectAccountByMailBox = mailbox =>
  createSelector(selecAccounts, accounts =>
    accounts.find(acc => acc.mailbox === mailbox),
  )

export const selectAccountsLoading = createSelector(
  selectAccountsFetching,
  loading => loading,
)

export const selectSending = createSelector(
  selectSendLoading,
  loading => loading,
)

export const selectFoldersLoading = createSelector(
  selectFolderListLoading,
  loading => loading,
)

export const selectEmailsLoading = createSelector(
  selectEmailsListLoading,
  loading => loading,
)

export const selectEmailDetailLoading = createSelector(
  selectEmailLoading,
  loading => loading,
)

export const selectActiveBoxData = createSelector(
  [selectActiveMailBox, selectActiveFolderBox, selectActiveEmailId],
  (current_box, current_folder, current_email) => ({
    current_box,
    current_folder,
    current_email,
  }),
)

export const selectEmailById = createSelector(
  [
    selectActiveMailBox,
    selectActiveFolderBox,
    selectActiveEmailId,
    selectMessage,
  ],
  (current_box, current_folder, emailId, message) =>
    message?.[current_box]?.[current_folder]?.[emailId] || {},
)

export const selectCurrentMessagesList = createSelector(
  [selectMessages, selectActiveMailBox, selectActiveFolderBox],
  (messages, current_box, current_folder) =>
    messages?.[current_box]?.[current_folder] || {},
)

export const selectFolderByMail = (mailbox: string) =>
  createSelector(selectFolders, folders => folders?.[mailbox]?.folders || [])

export const selectAliasesByMail = (mailbox: string) =>
  createSelector(selectFolders, folders => folders?.[mailbox]?.aliases || [])

export const selectCurrentFolderList = createSelector(
  [selectFolders, selectActiveMailBox],
  (folders, mailbox) => folders?.[mailbox]?.folders || [],
)

export const selectIsOpenCompose = createSelector(
  selectCompose,
  compose => compose.open,
)

export const selectComposeData = createSelector(
  selectCompose,
  compose => compose,
)

export const getFoldersThunk = createAsyncThunk<
  { folders: IFolder[]; aliases: IAliases },
  void,
  AsyncThunkAPI
>('emails/getFolders', async (_, { rejectWithValue, dispatch, getState }) => {
  try {
    const data = await API.foldersList()

    const folder = getState().orcatec.emailsSlice.current_folder
    if (!folder && data?.folders[0]?.path) {
      dispatch(setActiveFolderBox(data.folders[0].path))
    }

    return data
  } catch (error) {
    dispatch(getAccountsThunk())
    return rejectWithValue(error.response?.data)
  }
})

export const getAccountsThunk = createAsyncThunk<
  IAccountsData,
  void,
  AsyncThunkAPI
>('emails/getAccounts', async (_, { rejectWithValue, dispatch, getState }) => {
  try {
    const data = await API.getEmailAccounts()

    if (data?.data?.length) {
      const me = getState().orcatec.user.me
      dispatch(setTokenToRequestHeader(data.data?.[0]?.mail_token))

      dispatch(setActiveEmailBox(data.data?.[0]?.mailbox))

      setValueToLS(NAME_IN_LS(me.id), { accounts: data.data })
    }

    return data
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const loginToMailBoxThunk = createAsyncThunk<
  { token: string },
  number,
  AsyncThunkAPI
>('emails/loginToMailBox', async (id, { rejectWithValue }) => {
  try {
    return await API.loginToEmailAccount(id)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const createFolderThunk = createAsyncThunk<
  { token: string },
  { f_name: string },
  AsyncThunkAPI
>('emails/createFolder', async (data, { rejectWithValue }) => {
  try {
    return await API.createFolder(data)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const updateFolderThunk = createAsyncThunk<
  { from: string; to: string; mailbox: string },
  { from: string; to: string; mailbox: string },
  AsyncThunkAPI
>('emails/updateFolder', async (data, { rejectWithValue }) => {
  try {
    await API.updateFolder(data)

    return data
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const getEmailsListByFolderThunk = createAsyncThunk<
  { token: string },
  { page: number; perPage: number; folder?: string },
  AsyncThunkAPI
>(
  'emails/getEmailsListByFolder',
  async (data, { rejectWithValue, getState }) => {
    try {
      const activeFolder = getState().orcatec.emailsSlice.current_folder

      const req = {
        ...data,
        f_path: data?.folder || activeFolder,
      }
      return await API.getFolderMessages(req)
    } catch (error) {
      return rejectWithValue(error.response?.data)
    }
  },
)

export const getEmailDetailsThunk = createAsyncThunk<
  IMessage,
  string,
  AsyncThunkAPI
>('emails/getEmailDetails', async (m_uid, { rejectWithValue, getState }) => {
  try {
    const activeFolder = getState().orcatec.emailsSlice.current_folder

    const req = {
      m_uid,
      f_path: activeFolder,
    }
    return await API.getEmailDetails(req)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})
export const deleteEmailThunk = createAsyncThunk<
  { m_uid: string[]; f_path: string },
  string[],
  AsyncThunkAPI
>('emails/deleteEmail', async (m_uid, { rejectWithValue, getState }) => {
  try {
    const activeFolder = getState().orcatec.emailsSlice.current_folder

    const req = {
      m_uid,
      f_path: activeFolder,
    }
    await API.deleteMessages(req)

    return req
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const moveToThunk = createAsyncThunk<
  { m_uid: string[]; f_path: string; f_mv_path: string },
  { m_uid: string[]; f_mv_path: string },
  AsyncThunkAPI
>('emails/moveTo', async (data, { rejectWithValue, getState }) => {
  try {
    const activeFolder = getState().orcatec.emailsSlice.current_folder

    const req = {
      ...data,
      f_path: activeFolder,
    }
    await API.moveTo(req)

    return req
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const markAsFavoriteThunk = createAsyncThunk<
  { f_path: string; m_uid: string; flagged: string },
  { flagged: string; m_uid: string },
  AsyncThunkAPI
>('emails/setFavorite', async (data, { rejectWithValue, getState }) => {
  try {
    const activeFolder = getState().orcatec.emailsSlice.current_folder

    const req = {
      ...data,
      f_path: activeFolder,
    }
    await API.setFavorite(req)

    return req
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const changeFlagThunk = createAsyncThunk<
  {
    flag: string
    action: 'set' | 'unset'
    m_uid: number[]
  },
  {
    flag: string
    action: 'set' | 'unset'
    m_uid: number[]
  },
  AsyncThunkAPI
>('emails/changeFlag', async (data, { rejectWithValue, getState }) => {
  try {
    const activeFolder = getState().orcatec.emailsSlice.current_folder

    const req = {
      ...data,
      f_path: activeFolder,
    }
    await API.changeFlag(req)

    return req
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const deleteFolderThunk = createAsyncThunk<
  {
    f_path: string
    mailbox: string
  },
  {
    f_path: string
    mailbox: string
  },
  AsyncThunkAPI
>('emails/deleteFolder', async (data, { rejectWithValue }) => {
  try {
    await API.deleteFolder(data)

    return data
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const getUnseenCountByAccIdThunk = createAsyncThunk<
  {
    unseen_count: number
  },
  number,
  AsyncThunkAPI
>('emails/unseenCount', async (id, { rejectWithValue }) => {
  try {
    return await API.getUnseenMessages(id)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const sendEmailThunk = createAsyncThunk<
  null,
  { body: object; header: object; d_uid: number },
  AsyncThunkAPI
>('emails/sendEmail', async (data, { rejectWithValue, getState }) => {
  try {
    const accounts = getState()?.orcatec?.emailsSlice?.accounts

    const tokenForSending = accounts.find(item => item.mailbox === data?.from)
      .mail_token

    return await API.sendMail(data, tokenForSending)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const saveDraftEmailThunk = createAsyncThunk<
  {
    unseen_count: number
  },
  { body: object; header: object; d_uid: number; f_uid: string },
  AsyncThunkAPI
>('emails/draftEmail', async (data, { rejectWithValue }) => {
  try {
    return await API.saveDraft(data)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

startAppListening({
  matcher: isAnyOf(setActiveEmailBox, setActiveFolderBox, setActiveEmailId),

  effect: (action, { dispatch, getState }) => {
    const me = getState().orcatec.user.me

    if (isLoggedToMailBox(action)) {
      dispatch(getFoldersThunk())
      setValueToLS(NAME_IN_LS(me.id), { account: action.payload })
    }
    if (isChangeFolder(action) && action?.payload) {
      dispatch(
        getEmailsListByFolderThunk({
          folder: action.payload,
          page: 1,
          perPage: 20,
        }),
      )
      setValueToLS(NAME_IN_LS(me.id), { folder: action.payload })
    }
    if (isChangeEmailId(action) && action.payload) {
      dispatch(getEmailDetailsThunk(action.payload))
      setValueToLS(NAME_IN_LS(me.id), { email: action.payload })
    }
  },
})

const isLoggedToMailBox = (
  action: any,
): action is typeof setActiveEmailBox.type => {
  return action.type === setActiveEmailBox.type
}

const isChangeFolder = (
  action: any,
): action is typeof setActiveFolderBox.type => {
  return action.type === setActiveFolderBox.type
}

const isChangeEmailId = (
  action: any,
): action is typeof setActiveEmailId.type => {
  return action.type === setActiveEmailId.type
}
