export enum JobPermissions {
  JOBS_LIST_CAN_READ = 'jobs_list_can_read',
  JOBS_CAN_DELETE = 'jobs_can_delete',

  JOBS_CAN_CREATE_CUSTOM_FORMS = 'jobs_can_create_custom_forms',
  JOBS_CAN_READ_CUSTOM_FORMS = 'jobs_can_read_custom_forms',
  JOBS_CAN_EDIT_CUSTOM_FORMS = 'jobs_can_edit_custom_forms',
  JOBS_CAN_DELETE_CUSTOM_FORMS = 'jobs_can_delete_custom_forms',

  //Information for Workers
  JOBS_CAN_READ_INFO_FOR_WORKERS = 'jobs_can_read_info_for_workers',
  JOBS_CAN_EDIT_INFO_FOR_WORKERS = 'jobs_can_edit_info_for_workers',
  JOBS_CAN_DELETE_INFO_FOR_WORKERS = 'jobs_can_delete_info_for_workers',

  //Event group
  JOBS_CAN_VIEW_GROUP_EVENT = 'jobs_can_view_group_event',
  JOBS_CAN_EDIT_GROUP_EVENT = 'jobs_can_edit_group_event',
  JOBS_CAN_DELETE_GROUP_EVENT = 'jobs_can_delete_group_event',
}
